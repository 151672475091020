.category-section {
  margin-bottom: 2rem;
}

.category-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.category-tag {
  background-color: #e9ecef;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  color: #495057;
}

/* Style for react-select */
.react-select__control {
  margin-bottom: 1rem;
}

.geocoder-container {
  position: relative;
  width: 100%;
}

.mapboxgl-ctrl-geocoder {
  width: 100% !important;
  max-width: none !important;
  box-shadow: none;
  background-color: #fff;
}

.mapboxgl-ctrl-geocoder--input {
  padding: 6px 35px 6px 40px !important;
  border: none;
  background: transparent;
  border-radius: var(--border-radius-small) !important;
  background-color: white;
  box-shadow: var(--shadow-light);
  height: 40px;
  
}


.mapboxgl-ctrl-geocoder--input:focus {
  border-color: var(--primary-color);
  box-shadow: var(--shadow-medium);
  outline: none;
  border-radius: var(--border-radius-small);

}

.mapboxgl-ctrl-geocoder--icon {
  top: 10px;
  left: 10px;
}

.mapboxgl-ctrl-geocoder--button {
  background: none;
  border: none;
}

.mapboxgl-ctrl-geocoder--suggestion-title {
  font-weight: bold;
}

.mapboxgl-ctrl-geocoder--suggestions {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  margin-top: 2px;
}

.mapboxgl-ctrl-geocoder--suggestion {
  padding: 8px 12px;
  cursor: pointer;
}

.mapboxgl-ctrl-geocoder--suggestion:hover {
  background-color: #f8f9fa;
}

.mapboxgl-ctrl-geocoder--icon-loading {
  top: 10px !important;
  right: 10px !important;
  left: auto !important;
}
