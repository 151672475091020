/* Container styles */
html, body {
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
}

.home-page {
  max-width: 1050px;
  margin: 0 auto;
  padding: 24px;
  width: 100%;
}

.content-wrapper {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
}

/* Hero section */
.hero-section {
  display: flex;
  height: 500px;
  background-color: #EFF9F6;
  overflow: hidden;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: 48px;
  -webkit-transform: translateZ(0);  /* Force hardware acceleration */
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.hero-section::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 65%;
  background-color: #EFF9F6;
  z-index: 1;
}

.hero-content-wrapper {
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 0 24px;
}

.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 60%;
  height: 100%;
  padding: 64px 0;
  position: relative;
  z-index: 2;
}

.hero-content h1,
.hero-content h1 span {
  font-family: "Poppins", Helvetica, Arial, sans-serif !important;
  font-size: clamp(3rem, 6vw, 4.5rem) !important;
  font-weight: 700 !important;
  color: var(--primary-color) !important;
  line-height: 1.1 !important;
  margin-bottom: 1rem !important;
  letter-spacing: -0.02em !important;
  -webkit-font-smoothing: antialiased !important;
}

.hero-content p {
  font-size: 18px;
  color: var(--text-color);
  line-height: 1.5;
  margin: 0 0 16px 0;
  max-width: 500px;
}

.hero-image {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40%;
  background-image: url('../../assets/background-1440.webp');
  background-size: cover;
  background-position: 60% center;
  clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
  z-index: 1;
}

/* Profile completion section */
.profile-completion-header {
  padding: 16px 20px;
  border-bottom: 1px solid #eee;
}

.profile-completion-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
}

.profile-completion-title h3 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #0D243A;
  margin: 0;
}

.completion-percentage {
  font-size: 14px;
  color: #6c757d;
}

.profile-completion-progress {
  background-color: #f1f3f5;
  height: 4px;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 12px;
}

.profile-completion-progress .progress-bar {
  height: 100%;
  background-color: #0D243A;
  transition: width 0.6s ease;
}

.progress-bar.completed {
  background-color: var(--success-color, #28a745);
  transition: background-color 0.3s ease;
}

.profile-completion-success {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.profile-completion-success .completion-task {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: var(--background-light, #f8f9fa);
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  max-width: 400px;
  transition: all 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.profile-completion-success .completion-task:hover {
  background-color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.completion-task-content {
  flex: 1;
  min-width: 0;
}

.completion-task-title {
  font-size: 14px;
  font-weight: 500;
  color: #0D243A;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.completion-task-description {
  font-size: 12px;
  color: #6c757d;
  margin: 4px 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.completion-task-chevron {
  font-size: 24px;
  color: var(--text-muted, #6c757d);
  margin-left: 1rem;
  transition: transform 0.2s ease;
}

.completion-task:hover .completion-task-chevron {
  transform: translateX(2px);
}

.profile-completion-actions {
  display: flex;
  justify-content: center;
  gap: 24px;
  align-items: center;
}

.completion-link {
  color: var(--text-gray, #6c757d);
  text-decoration: none;
  font-size: 14px;
  transition: color 0.2s ease;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.completion-link:hover {
  color: var(--primary-color);
  text-decoration: none;
}

/* Completion tasks */
.completion-tasks {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 12px;
  padding: 16px 0;
  width: 100%;
}

.completion-task {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.completion-task:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.completion-task.completed {
  background-color: #f8f9fa;
  border-color: #eee;
}

.completion-task-check {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #e8f5e9;
  color: #02b875;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

/* Project cards */
.project-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  padding: 16px 0;
  width: 100%;
}

.project-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 6px;
  overflow: hidden;
  text-decoration: none;
  transition: all 0.2s ease;
}

.project-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.project-card-logo {
  background-color: #0D243A;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
}

.project-card-content {
  padding: 12px 16px;
}

.project-card-content h3 {
  font-size: 16px;
  font-weight: 600;
  color: #0D243A;
  margin: 0 0 4px 0;
}

.project-card-content p {
  font-size: 14px;
  color: #6c757d;
  margin: 0;
}

/* Logo sizes */
.project-card-logo {
  background-color: #0D243A;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
}

.vattenfall-logo {
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wpd-logo {
  width: 200px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vattenfall-logo svg,
.wpd-logo svg {
  width: 100%;
  height: 100%;
}

/* Specific adjustment for WPD logo */
.wpd-logo svg {
  height: 70px;  /* Fixed height instead of 100% */
}

/* How it works section */
.how-it-works-section {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.steps-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  width: 100%;
}

.step-card {
  padding: 16px;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 6px;
}

.step-card h3 {
  font-size: 14px;
  font-weight: 600;
  color: #0D243A;
  margin: 0 0 8px 0;
}

.step-card p {
  font-size: 13px;
  color: #6c757d;
  line-height: 1.4;
  margin: 0;
}

/* Mobile styles */
@media (max-width: 768px) {
  .home-page {
    padding: 16px;
  }

  .hero-section {
    height: auto;
    min-height: 400px;
    margin: 0 -16px 32px -16px;
    width: calc(100% + 32px);
    left: 0;
  }

  .hero-content {
    width: 60% !important;
    padding: 48px 24px;
  }

  .hero-content-wrapper {
    padding: 0;
  }

  .hero-image {
    width: 40% !important;
    display: block !important;
  }

  .hero-content h1,
  .hero-content h1 span {
    font-size: clamp(2rem, 8vw, 2.5rem) !important;
  }

  .hero-content p {
    font-size: 16px;
  }

  .completion-tasks {
    display: flex !important;
    flex-direction: row !important;
    gap: 12px !important;
    padding: 16px 20px !important;
    overflow-x: auto !important;
    scroll-snap-type: x mandatory !important;
    -webkit-overflow-scrolling: touch !important;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
  }

  .completion-tasks::-webkit-scrollbar {
    display: none !important;
  }

  .completion-task {
    flex: 0 0 calc(50% - 6px) !important;
    min-width: calc(50% - 6px) !important;
    max-width: calc(50% - 6px) !important;
    scroll-snap-align: start !important;
  }

  .project-cards {
    grid-template-columns: 1fr;
    gap: 12px;
    padding: 12px 16px;
  }

  .project-card-logo {
    height: 200px;
    padding: 40px;
  }

  .steps-container {
    grid-template-columns: 1fr;
    gap: 12px;
    padding: 12px 16px;
  }

  .vattenfall-logo {
    width: 240px;
    height: 48px;
  }

  .wpd-logo {
    width: 240px;
    height: 84px;
  }

  .wpd-logo svg {
    height: 84px;
  }
}

/* Welcome Modal Styles */
.welcome-modal .modal-header {
  padding: 16px 20px;
  border-bottom: 1px solid var(--light-gray-1);
}

.welcome-modal .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

.welcome-modal .modal-body {
  padding: 16px 20px;
}

.welcome-content {
  color: var(--text-color);
}

.welcome-step {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 12px;
}

.welcome-description {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 16px;
}

.welcome-tip {
  background-color: #EFF9F6;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 14px;
  color: var(--text-color);
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-top: 16px;
}

.welcome-tip strong {
  color: var(--primary-color);
}

.welcome-modal .modal-footer {
  padding: 16px 20px;
  border-top: 1px solid var(--light-gray-1);
  display: flex;
  gap: 12px;
}

.welcome-modal .btn-close-modal,
.welcome-modal .btn-complete-profile {
  height: 50px;
  font-weight: 500;
  font-size: 15px;
  border-radius: 4px;
  flex: 1;
  min-width: 100% !important;
}

.welcome-modal .btn-close-modal {
  background-color: #EFF9F6;
  color: #2D3436;
  border: none;
}

.welcome-modal .btn-complete-profile {
  background-color: var(--primary-color);
  color: white;
  border: none;
}

/* Mobile optimizations for welcome modal */
@media (max-width: 768px) {
  .welcome-modal .modal-header {
    padding: 16px 20px;
  }
  
  .welcome-modal .modal-body {
    padding: 16px 20px;
  }

  .welcome-step {
    font-size: 14px;
  }

  .welcome-description {
    font-size: 13px;
  }

  .welcome-tip {
    font-size: 13px;
    padding: 10px 14px;
  }

  .welcome-modal .modal-footer {
    padding: 12px 16px;
    flex-direction: column;
  }

  .welcome-modal .btn-close-modal,
  .welcome-modal .btn-complete-profile {
    width: 100%;
    margin: 0;
  }
}

/* Make sure modal doesn't cause overflow */
.modal-dialog {
  max-width: 90%;
  margin: 1.75rem auto;
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 500px;
  }
}
