.location-map,
.location-map-placeholder {
  width: 100%;
  height: 400px;
  border-radius: var(--border-radius-medium);
  overflow: hidden;
}

.location-map-placeholder {
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dee2e6;
}

.location-map-placeholder p {
  color: #6c757d;
  margin: 0;
}

/* Profile-specific map styles */
.profile-map {
  pointer-events: none;
}

.profile-map .mapboxgl-control-container {
  pointer-events: auto;
}

.profile-map .mapboxgl-ctrl-group > button:not(.mapboxgl-ctrl-zoom-in):not(.mapboxgl-ctrl-zoom-out) {
  display: none;
}

/* Ensure map and its children maintain border radius */
.profile-map .mapboxgl-map,
.profile-map .mapboxgl-canvas-container,
.profile-map .mapboxgl-canvas {
  border-radius: var(--border-radius-medium) !important;
}

@media (max-width: 768px) {
  .location-map,
  .location-map-placeholder {
    height: 300px;
  }
}
