/* General Card Styling */
.company-card {
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  padding: calc(var(--spacing-unit) * 2);
  margin-bottom: calc(var(--spacing-unit) * 2);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  cursor: pointer;

}

/* Hover effect for the card */
.company-card:hover {
  box-shadow: var(--shadow-medium);
  transform: translateY(-2px);
  text-decoration: none;
}

/* Selected card state */
.company-card.selected-card {
  border-color: var(--link-color);
  box-shadow: var(--shadow-medium);
}

/* Card body with better padding and spacing */
.card-body {
  padding: 20px;
}

/* Card title - base styles for all screen sizes */
.card-title {
  margin: 0;
  font-family: var(--font-family-base);
  font-size: var(--font-size-lg);
  font-weight: var(--text-weight-semibold);
  height: 2.4em !important;
  min-height: 2.4em !important;
  max-height: 2.4em !important;
  width: 100%;
  display: flex !important;
  align-items: center !important;
}

.card-title > span {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  line-height: var(--line-height-tight);
  width: 100%;
}

/* Card text */
.card-text {
  font-size: var(--text-base);
  color: var(--text-gray);
  margin-bottom: calc(var(--spacing-unit) * 0.5);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Card text labels */
.card-text strong {
  font-weight: var(--text-weight-semibold);
  color: var(--text-color);
}

/* Link styling for card */
.company-card {
  text-decoration: none;
  color: inherit;
  display: block;
}

/* Responsive Design: Adjust card layout for mobile */
@media (max-width: 576px) {
  .company-card {
    padding: calc(var(--spacing-unit));
    max-width: 95% !important;
  }

  .card-body {
    padding: 10px;
    height: 20px;
  }

  .card-header {
    gap: 12px;
    margin-bottom: 8px;
    padding-bottom: 8px;
  }

  .company-card .profile-image-wrapper,
  .company-card .profile-image-placeholder {
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    min-height: 50px !important;
  }

  .card-title {
    font-size: 1rem;
    line-height: 1.2;
  }

  .card-text {
    font-size: 0.875rem;
    margin-bottom: 4px;
  }

  .card-description {
    font-size: 0.875rem;
    margin-top: 4px;
    -webkit-line-clamp: 1; /* Changed from 3 to 1 to match desktop */
    max-height: calc(1 * var(--line-height-normal) * 1em); /* Updated to match one line */
  }

  .category-tag {
    font-size: 0.75rem;
    padding: 4px 8px;
    margin: 2px;
  }
}

/* Card header - base styles */
.card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--light-gray-1);
  min-height: 70px !important;
}

/* Add wrapper styling */
.company-card .profile-image-wrapper {
  width: 70px !important;
  height: 70px !important;
  min-width: 70px !important;
  min-height: 70px !important;
  border-radius: 50% !important;
  background: white !important;
  border: 3px solid white !important;
  padding: 0 !important;
  box-shadow: var(--shadow-light);
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  overflow: hidden !important;
  position: relative !important;
}

/* Update image styling to ensure proper centering and scaling */
.company-card .profile-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  background: transparent !important;
  position: relative !important;
  z-index: 1 !important;
  padding: 0 !important;
  display: block !important;
  margin: auto !important;
}

/* Fix the background pseudo-element */
.company-card .profile-image-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(249, 249, 249);
  z-index: 0;
}

/* Placeholder icon styling */
.company-card .profile-image-placeholder {
  position: relative;
  width: 70px !important;
  height: 70px !important;
  min-width: 70px !important;
  min-height: 70px !important;
  border-radius: 50% !important;
  margin-bottom: 12px;
  color: var(--link-color);
  background-color: var(--light-gray-1);  /* Keep this gray for the placeholder */
  padding: calc(var(--spacing-unit));
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  box-shadow: var(--shadow-light);
}

/* Responsive Design: Adjust layout for mobile */
@media (max-width: 768px) {
  .card-header {
    flex-direction: row;
    align-items: center;
  }

  .profile-image,
  .profile-image-placeholder {
    margin-bottom: 0;
    margin-right: 16px;
  }

  .card-title {
    font-size: var(--font-size-base);
  }
}

.category-tag {
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--light-gray-1);
  color: var(--text-color);
  padding: calc(var(--spacing-unit) * 0.5) calc(var(--spacing-unit));
  border-radius: var(--border-radius-pill);
  font-size: var(--text-small);
  background-color: transparent;
  white-space: nowrap;
}

.more-categories {
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--light-gray-1);
  color: var(--text-gray);
  font-size: var(--text-small);
  white-space: nowrap;
  padding: calc(var(--spacing-unit) * 0.5) calc(var(--spacing-unit));
  border-radius: var(--border-radius-pill);
  margin-left: 4px;
}

.card-description {
  font-size: var(--text-base);
  font-weight: var(--text-weight-normal);
  color: var(--text-color);
  margin-top: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: var(--line-height-normal);
  max-height: calc(2 * var(--line-height-normal) * 1em);
}

.company-card .profile-image-placeholder svg {
  color: white;
}

@media (max-width: 768px) {
  .search-page .company-card {
    width: 300px !important;
    min-width: 300px !important;
    height: 150px !important;
    margin: 0 !important;
    padding: calc(var(--spacing-unit));
    flex-shrink: 0;
    margin-left: 20px !important;
    
  }

  .card-body {
    padding: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .card-header {
    gap: 8px;
    margin-bottom: 4px;
    padding-bottom: 4px;
    min-height: 48px !important; /* Smaller height for mobile */
  }

  .company-card .profile-image-wrapper,
  .company-card .profile-image-placeholder {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
  }

  .card-description {
    flex-grow: 0;
    -webkit-line-clamp: 1;
    max-height: 1.2em;
  }

  .card-categories {
    margin-top: 4px;
    max-height: 2.4em;
  }

  .card-title {
    font-size: 1rem;
    line-height: 1.2;
    height: 2.4em !important;
    min-height: 2.4em !important;
    margin-bottom: 4px !important;
    display: flex;
    align-items: center;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.card-categories {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 4px;
  align-items: center;
  max-height: calc(2 * (var(--spacing-unit) * 2 + var(--text-small)));
}

.category-tag {
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--light-gray-1);
  color: var(--text-color);
  padding: calc(var(--spacing-unit) * 0.5) calc(var(--spacing-unit));
  border-radius: var(--border-radius-pill);
  font-size: var(--text-small);
  background-color: transparent;
  white-space: nowrap;
}

.more-categories {
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--light-gray-1);
  color: var(--text-gray);
  font-size: var(--text-small);
  white-space: nowrap;
  padding: calc(var(--spacing-unit) * 0.5) calc(var(--spacing-unit));
  border-radius: var(--border-radius-pill);
  margin-left: 4px;
}
