.cookie-banner {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(74, 74, 74, 0.95);
  color: var(--matterColorNegative, #e7e7e7);
  padding: 16px 24px;
  font-family: "poppins", Helvetica, Arial, sans-serif;
  font-weight: 500;
  letter-spacing: -0.1px;
  font-size: 16px;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
}

.cookie-message {
  margin-right: 24px;
}

.cookie-link {
  color: inherit;
  text-decoration: underline;
}

.cookie-link:hover {
  color: inherit;
}

.continue-btn {
  background: transparent;
  border: 1px solid var(--matterColorNegative, #e7e7e7);
  color: var(--matterColorNegative, #e7e7e7);
  padding: 8px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  transition: all 0.2s ease;
}

.continue-btn:hover {
  background-color: var(--matterColorNegative, #e7e7e7);
  color: var(--matterColor, #4a4a4a);
}

/* Mobile styles */
@media (max-width: 768px) {
  .cookie-banner {
    font-size: 14px;
    padding: 12px 16px;
  }

  .cookie-message {
    margin-right: 16px;
  }

  .continue-btn {
    padding: 6px 16px;
  }
}
