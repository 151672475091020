.about-page {
  max-width: 1050px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.about-container {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.about-page h1 {
  margin-bottom: 1.5rem;
}

.about-page section {
  margin: 2rem 0;
}

.about-page .mission {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.about-page ul {
  padding-left: 1.5rem;
  margin: 1rem 0;
}

.about-page li {
  margin: 0.5rem 0;
}

.message {
  background: #f5f5f5;
  padding: 1.5rem;
  border-radius: 4px;
  margin-top: 3rem;
}

.source-link {
  color: #007bff;
  text-decoration: none;
}

.source-link:hover {
  text-decoration: underline;
}
