.help-page {
    padding: 48px 24px;
    max-width: 1050px;
    margin: 0 auto;
  }
  
  .help-container {
    background-color: var(--background-color);
    padding: 32px;
    border-radius: var(--border-radius-large);
    box-shadow: var(--shadow-light);
  }
  
  .help-header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;
  }
  
  .help-icon {
    font-size: 32px;
    color: var(--primary-color);
  }
  
  .help-header h1 {
    font-size: var(--font-size-3xl);
    font-weight: var(--text-weight-semibold);
    color: var(--text-color);
    margin: 0;
  }
  
  .help-description {
    font-size: var(--font-size-lg);
    color: var(--text-gray);
    margin-bottom: 32px;
    line-height: var(--line-height-relaxed);
  }
  
  .help-sections {
    display: flex;
    flex-direction: column;
  }
  
  .help-section h2 {
    font-size: var(--font-size-xl);
    font-weight: var(--text-weight-semibold);
    color: var(--text-color);
    margin-bottom: 16px;
  }
  
  .help-section p {
    font-size: var(--font-size-base);
    color: var(--text-gray);
    line-height: var(--line-height-normal);
  }
  
  .help-contact-section {
    background-color: var(--secondary-color);
    padding: 24px;
    border-radius: var(--border-radius-medium);
    margin-top: 16px;
  }
  
  .help-contact-text {
    font-size: var(--font-size-base);
    color: var(--text-color);
    margin-bottom: 16px;
  }
  
  .help-contact-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 24px;
  }
  
  .button-icon {
    font-size: 18px;
  }
  
  @media (max-width: 576px) {
    .help-page {
      padding: 24px 16px;
    }
    
    .help-container {
      padding: 24px;
    }
    
    .help-header {
      margin-bottom: 24px;
    }
    
    .help-header h1 {
      font-size: var(--font-size-2xl);
    }
    
    .help-description {
      font-size: var(--font-size-base);
      margin-bottom: 24px;
    }
  }