.project-header-container {
  max-width: 1050px;
  margin: 0 auto;
  padding: 20px;
}

.project-header {
  display: flex;
  margin-bottom: 40px;
  box-shadow: var(--shadow-large);
  border-radius: var(--border-radius-medium);
  background-color: white;
  height: 550px;
}

/* Update media query for mobile */
@media (max-width: 768px) {
  .project-header {
    flex-direction: column;
    border-radius: var(--border-radius-medium);

    height: auto;
  }

  .project-header-info {
    padding: 25px;
    padding-right: 25px;
    min-height: 200px; /* Ensure some minimum height for content */
  }

  .project-header-map {
    position: relative;
    border-radius: 0 0 var(--border-radius-medium) var(--border-radius-medium);
    height: 300px;
    flex: none;
    
  }

  /* Add these to ensure map and its container are visible */
  .project-header-map > div {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;

  }

  /* Ensure the MapComponent takes full height of its container */
  .project-header-map .mapboxgl-map {
    height: 100% !important;
    border-radius: 0 0 var(--border-radius-medium) var(--border-radius-medium) !important;
  }

  /* Ensure canvas and container also have matching corners */
  .project-header-map .mapboxgl-canvas-container,
  .project-header-map .mapboxgl-canvas {
    border-radius: 0 0 var(--border-radius-medium) var(--border-radius-medium) !important;
    
  }
}

.project-header-info {
  flex: 3;
  padding-right: 40px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-header-info h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.project-header-info p {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
}

.follow-project-btn {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.project-header-map {
  flex: 4;
  overflow: hidden;
  border-radius: 0 var(--border-radius-medium) var(--border-radius-medium) 0;
  height: 100%;
  display: flex;
  position: relative;
}

@media (max-width: 768px) {
  .project-header-map {
    border-radius: 0 0 var(--border-radius-medium) var(--border-radius-medium);
  }
}

.project-header-map > div {
  width: 100%;
  height: 100%;

}

.project-header-info img {
  max-width: 100%;
  height: auto;
}

.header-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: var(--border-radius-medium) var(--border-radius-medium) 0 0;
}
