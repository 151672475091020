/* Container Styling */
.profile-page {
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: calc(var(--spacing-unit) * 4);
  margin: calc(var(--spacing-unit) * 2) auto;
  max-width: 1050px;
}

/* Header Styling */
.profile-page h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: calc(var(--spacing-unit) * 1);
}

.profile-page h3 {
  font-size: 1.75rem;
  font-weight: 400;
  color: var(--text-gray);
  margin-bottom: calc(var(--spacing-unit) * 3);
}

/* Button Styling */
button, .btn {
  padding: calc(var(--spacing-unit) * 1) calc(var(--spacing-unit) * 2);
  font-size: 1rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-primary {
  background-color: var(--primary-color);
  color: #fff;
}

.btn-primary:hover {
  background-color: #0a1b2a;
}

.btn-warning {
  background-color: var(--warning-orange);
  color: #fff;
}

.btn-warning:hover {
  background-color: #e69500;
}

.btn-info {
  background-color: var(--accent-color);
  color: #fff;
}

.btn-info:hover {
  background-color: #204966;
}

/* Form Control Styling - Updated */
.form-control {
  border-radius: var(--border-radius-small) !important;
  padding: calc(var(--spacing-unit) * 1.5);
  border: 1px solid var(--light-gray);
  background-color: white;
  box-shadow: var(--shadow-light);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: calc(var(--spacing-unit) * 2);
}

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: var(--shadow-medium);
  outline: none;
  border-radius: var(--border-radius-small);

}

/* Form Group Styling - Updated */
.form-group {
  margin-bottom: calc(var(--spacing-unit) * 3);
}

.form-group label {
  display: block;
  margin-bottom: calc(var(--spacing-unit) * 1);
  color: var(--text-gray);
  font-weight: 500;
}

/* Textarea specific styling */
textarea.form-control {
  border-radius: var(--border-radius-small) !important;
  padding: calc(var(--spacing-unit) * 1.5);
  background-color: white;
  box-shadow: var(--shadow-light);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: calc(var(--spacing-unit) * 2);
  min-height: 120px;
  resize: vertical;
}

/* Offer group styling - Updated */
.offer-group {
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: var(--border-radius);
  padding: calc(var(--spacing-unit) * 2);
  margin-bottom: calc(var(--spacing-unit) * 2);
  
}



.offer-group + .offer-group {
  margin-top: calc(var(--spacing-unit) * 2);
}

/* Category section styling - Updated */
.category-section {
  background-color: white;
  padding: calc(var(--spacing-unit) * 2);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-light);
  margin-top: calc(var(--spacing-unit) * 3);
}

/* Profile edit form container - New */
.profile-edit-form {
  background-color: white;
  padding: calc(var(--spacing-unit) * 3);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-medium);
}

/* Section headers - New */
.form-section-header {
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: calc(var(--spacing-unit) * 3);
  margin-bottom: calc(var(--spacing-unit) * 2);
  padding-bottom: calc(var(--spacing-unit) * 1);
  border-bottom: 2px solid var(--secondary-color);
}

/* Adjust the first heading to not have a top margin */
.form-section-header:first-of-type {
  margin-top: 0;
}

/* Offers Section */
.offers {
  margin-top: calc(var(--spacing-unit) * 4);
}

.offers h3 {
  color: var(--primary-color);
  font-weight: 600;
  margin-bottom: calc(var(--spacing-unit) * 2);
}

/* Accordion Styling */
.accordion .accordion-item {
  border: none;
  border-radius: var(--border-radius);
  overflow: hidden;
  margin-bottom: calc(var(--spacing-unit) * 2);
  box-shadow: var(--shadow-light);
}

.accordion-button {
  color: var(--text-gray);
  font-weight: 600;
  padding: calc(var(--spacing-unit) * 1.5);
  border: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  height: 65px;
  border-radius: var(--border-radius);
}

.accordion-button:not(.collapsed) {
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 0px;

}



.accordion-body {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: calc(var(--spacing-unit) * 2);
  font-weight: var(--text-weight-normal);
}





/* Alert Styling */
.alert {
  border-radius: var(--border-radius);
  padding: calc(var(--spacing-unit) * 2);
  margin-top: calc(var(--spacing-unit) * 2);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .profile-page {
    padding: calc(var(--spacing-unit) * 2);
  }

  .profile-page h1 {
    font-size: 2rem;
  }

  .profile-page h3 {
    font-size: 1.5rem;
  }

  .accordion-button {
    padding: calc(var(--spacing-unit) * 1);
    font-size: 1rem;
  }

  .accordion-body {
    padding: calc(var(--spacing-unit) * 1.5);
  }
}

/* Update existing ProfilePage.css */

.profile-container {
  position: relative; /* This ensures absolute positioning works within this container */
  max-width: 1100px;
  margin: 0 auto;
  padding: calc(var(--spacing-unit) * 2);
  padding-top: 2rem;
}

.profile-header {
  position: relative;
  display: flex;
  align-items: center;
  gap: calc(var(--spacing-unit) * 2);
  margin-bottom: 2rem;
}

.profile-header.has-edit-button {
  padding-top: 48px;
}

.edit-profile-btn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1;
  background-color: var(--warning-orange) !important;
  border: none !important;
  color: white !important;
  width: auto !important;
  min-width: 120px;
  justify-content: center;
}

.profile-image-container {
  aspect-ratio: 1/1 !important;
  width: 120px !important;
  height: 120px !important;
  min-width: 120px !important;
  min-height: 120px !important;
  border-radius: 50% !important;
  background: white !important;
  border: 3px solid white;
  padding: 0 !important;
  box-shadow: var(--shadow-medium);
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  overflow: hidden !important;
  position: relative !important;
}

/* Update profile image styling */
.profile-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  background: transparent !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 1 !important;
}

/* Add white background pseudo-element */
.profile-image-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(255, 255, 255);
  z-index: 0;

}

/* Update placeholder styling to match */
.profile-page .profile-image-placeholder,
.profile-container .profile-image-placeholder {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-gray-1);
  color: var(--link-color);
  font-size: 2.5rem;
  border-radius: 50%;
  z-index: 1;
}

.profile-page .profile-image-placeholder svg,
.profile-container .profile-image-placeholder svg {
  color: white;
  width: 50%;
  height: 50%;
}

.profile-content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: calc(var(--spacing-unit) * 4); /* Increased from 3 to 4 */
  align-items: start;
}

.profile-main {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-unit) * 2);
  margin-bottom: 2rem;
}

.profile-sidebar {
  position: sticky;
  top: 2rem; /* Reduced from previous value to push it up slightly */
  background: #fff;
  border-radius: var(--border-radius-medium);
  padding: calc(var(--spacing-unit) * 2);
  box-shadow: var(--shadow-large);
  height: fit-content;
  width: 100%;
}

.profile-sidebar .profile-map {
  height: 400px !important;
  width: 100%;
  margin-bottom: calc(var(--spacing-unit) * 2);
}

.contact-buttons {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-unit) * 1);
}



.category-tags {
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--spacing-unit) * 1);
}

@media (max-width: 768px) {
  .profile-content {
    grid-template-columns: 1fr;
  }
  
  .profile-sidebar {
    position: static;
    margin-top: calc(var(--spacing-unit) * 2);
  }

  /* Remove the padding on mobile */
  .profile-container .col-md-7,
  .profile-container .col-md-5 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .profile-sidebar .profile-map {
    height: 300px !important;
  }
}

.category-tag {
  background-color: var(--secondary-color);
  color: var(--text-color);
  padding: calc(var(--spacing-unit) * 0.5) calc(var(--spacing-unit) * 1);
  border-radius: var(--border-radius-pill);
  font-size: 0.9rem;
}

/* Additional style updates */
.about-section {
  margin-bottom: calc(var(--spacing-unit) * 2);
}

.about-section h2 {
  font-size: 1.5rem;
  margin-bottom: calc(var(--spacing-unit) * 1);
}

.company-info {
  margin-top: calc(var(--spacing-unit) * 2);
}
.company-info  {
  font-size: var(--text-small);
  color: var(--text-light-gray) !important;
  font-weight: var(--text-weight-normal);
  margin-top: calc(var(--spacing-unit) * 1);
}


.company-address {
  font-size: 0.85rem;
  color: var(--text-gray);
}

.company-number {
  font-size: 0.85rem;
  color: var(--text-gray);
}

.contact-buttons {
  margin: calc(var(--spacing-unit) * 2) 0;
}

.contact-buttons .btn {
  margin-bottom: calc(var(--spacing-unit) * 1);
}

/* Add these styles for the sticky save button */
.sticky-save-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: calc(var(--spacing-unit) * 2);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: center;
  gap: calc(var(--spacing-unit) * 2);
}

/* Add padding to the bottom of the container when in edit mode */
.profile-container.edit-mode {
  padding-bottom: calc(var(--spacing-unit) * 10);
}


/* Add this new class for the title container */
.profile-title-container {
  flex: 1;
  min-width: 0; /* Prevents flex item from overflowing */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers content vertically */
  gap: calc(var(--spacing-unit) * 0.5); /* Reduced gap between title and company info */
}

.profile-title-container h1 {
  margin: 0; /* Removes default margins that might affect centering */
  line-height: 1.2; /* Helps with text wrapping */
  word-wrap: break-word; /* Ensures long titles wrap properly */
}

/* Remove the complex sidebar wrapper styles */
.sidebar-wrapper {
  position: relative;
}

.profile-title-container .company-info {
  margin: 0; /* Remove default margins */
  line-height: 1.2; /* Tighter line height */
}

/* Update edit button positioning */
.edit-profile-btn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1;
  background-color: var(--warning-orange) !important;
  border: none !important;
  color: white !important;
  width: auto !important;
  min-width: 120px;
  justify-content: center;
}

.edit-profile-btn:hover {
  background-color: rgb(230, 160, 50) !important;
  transform: scale(1.05);
}

/* Make sure the profile header has the correct positioning context */
.profile-container {
  position: relative; /* Add this to ensure absolute positioning works correctly */
}

/* Container styles */
.profile-container {
  position: relative; /* This ensures absolute positioning works within this container */
  max-width: 1100px;
  margin: 0 auto;
  padding: calc(var(--spacing-unit) * 2);
  padding-top: 2rem;
}

/* Profile header styles */
.profile-header {
  position: relative;
  display: flex;
  align-items: center;
  gap: calc(var(--spacing-unit) * 2);
  margin-bottom: 2rem;
}


/* Add at the top of the file */
.profile-container {
  min-height: calc(100vh - 60px); /* Subtract footer height */
  position: relative;
  max-width: 1100px;
  margin: 0 auto;
  padding: calc(var(--spacing-unit) * 2);
  padding-top: 2rem;
  padding-bottom: calc(var(--spacing-unit) * 4); /* Add some bottom padding */
}

/* Update container styles */
.profile-container {
  position: relative; /* This ensures absolute positioning works within this container */
  max-width: 1100px;
  margin: 0 auto;
  padding: calc(var(--spacing-unit) * 2);
  padding-top: 2rem;
}

@media (max-width: 768px) {
  .profile-container {
    padding: calc(var(--spacing-unit) * 2); /* Add consistent padding on mobile */
  }

  .profile-content {
    grid-template-columns: 1fr;
  }
  
  .profile-sidebar {
    position: static;
    margin-top: calc(var(--spacing-unit) * 2);
  }

  /* Remove the padding on mobile */
  .profile-container .col-md-7,
  .profile-container .col-md-5 {
    padding-left: calc(var(--spacing-unit) * 2) !important;
    padding-right: calc(var(--spacing-unit) * 2) !important;
  }
}

/* Update accordion button styles */
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23666'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transition: transform 0.3s ease, background-image 0.3s ease;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

/* Add media query for mobile adjustments */
@media (max-width: 768px) {
  .profile-image-container {
    aspect-ratio: 1/1 !important;
    width: 100px !important;
    height: 100px !important;
    min-width: 100px !important;
    min-height: 100px !important;
    flex: 0 0 100px !important;
  }
}

/* Update contact buttons container */
.contact-buttons {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-unit) * 1);
}

/* Add new mobile contact buttons styles */
@media (max-width: 768px) {
  .contact-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: column;
    padding: calc(var(--spacing-unit) * 1.5);
    background: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin: 0;
    gap: calc(var(--spacing-unit) * 1);
  }

  /* Hide contact buttons in edit mode */
  .profile-container.edit-mode .contact-buttons {
    display: none;
  }

  .contact-buttons .btn {
    width: 100%;
    margin: 0;
  }

  /* Add padding to bottom of container to prevent content being hidden behind sticky buttons */
  .profile-container {
    padding-bottom: calc(var(--spacing-unit) * 12);
  }

  /* Adjust padding in edit mode since contact buttons are hidden */
  .profile-container.edit-mode {
    padding-bottom: calc(var(--spacing-unit) * 8);
  }
}

.translate-btn.btn-link {
  padding: 4px 8px;
  margin: 0;
  border: none;
  background: none !important;
  box-shadow: none !important;
  text-decoration: none;
  color: var(--link-color) !important;
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 0.9rem;
  white-space: nowrap;
  transition: opacity 0.3s ease;
}

.translate-btn.btn-link:disabled {
  color: var(--text-gray) !important;
  opacity: 0.7;
}

.translate-btn.btn-link:hover,
.translate-btn.btn-link:focus,
.translate-btn.btn-link:active {
  text-decoration: none;
  background: none !important;
  box-shadow: none !important;
  color: var(--link-hover-color) !important;
}

.translate-btn.btn-link:focus {
  outline: none;
}

/* Remove any Bootstrap button styles */
.translate-btn.btn-link::after,
.translate-btn.btn-link::before {
  display: none;
}

.about-section .translate-controls {
  margin-bottom: 0.5rem;
}

/* Update translate controls and button styling */
.about-section {
  position: relative;
}

.translate-controls {
  margin-top: 0.5rem;
  width: fit-content;
}

.translate-btn.btn-link {
  padding: 4px 8px;
  margin: 0;
  border: none;
  background: none !important;
  box-shadow: none !important;
  text-decoration: none;
  color: var(--link-color) !important;
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 0.9rem;
  white-space: nowrap;
  transition: opacity 0.3s ease;
}

/* Add responsive adjustments */
@media (max-width: 768px) {
  .translate-controls {
    position: static;
  }
}

/* Add these styles for the translate button loading state */
.translate-btn.loading {
  opacity: 0.7;
  cursor: not-allowed;
}

.translate-btn.loading:hover {
  opacity: 0.7;
}

/* Add these styles for the company number search container */
.company-number-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
}

.company-number-container .search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-gray);
  z-index: 1;
}

.company-number-container .form-control {
  width: 100%;
  margin: 0; /* Remove any default margins */
}

.company-number-container .spinner-border {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
}

/* Style the dropdown menu */
.company-number-container .dropdown-menu {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 4px;
  border: 1px solid var(--light-gray);
  border-radius: var(--border-radius-small);
  box-shadow: var(--shadow-medium);
  z-index: 1000;
}

.company-number-container .dropdown-item {
  padding: 12px 16px;
  cursor: pointer;
  border-bottom: 1px solid var(--light-gray);
}

.company-number-container .dropdown-item:last-child {
  border-bottom: none;
}

.company-number-container .dropdown-item:hover {
  background-color: var(--light-gray-1);
}

.company-number-container .dropdown-item strong {
  display: block;
  color: var(--text-color);
  margin-bottom: 4px;
}

.company-number-container .dropdown-item small {
  color: var(--text-gray);
}

/* Update dropdown menu styles for new structure */
.company-number-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
}

.company-number-container .search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-gray);
  z-index: 1;
}

.company-number-container .form-control {
  width: 100%;
  margin: 0; /* Remove any default margins */
}

.company-number-container .spinner-border {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
}

.dropdown-menu-company-number {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  max-width: 100%;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid var(--light-gray);
  border-radius: var(--border-radius-small);
  box-shadow: var(--shadow-medium);
  background-color: white;
  margin: 0;
  z-index: 1000;
}

.dropdown-menu-company-number .dropdown-item {
  padding: 12px 16px;
  cursor: pointer;
  border-bottom: 1px solid var(--light-gray);
}

.dropdown-menu-company-number .dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-menu-company-number .dropdown-item:hover {
  background-color: var(--light-gray-1);
}

.dropdown-menu-company-number .dropdown-item strong {
  display: block;
  color: var(--text-color);
  margin-bottom: 4px;
}

.dropdown-menu-company-number .dropdown-item small {
  color: var(--text-gray);
}

/* Update dropdown menu positioning */
#companyNumber.position-relative {
  z-index: 1000;
}

#companyNumber .dropdown-menu {
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  max-width: 100%;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid var(--light-gray);
  border-radius: var(--border-radius-small);
  box-shadow: var(--shadow-medium);
  background-color: white;
  margin: 0; /* Remove any default margins */
  transform: none !important;
  position: absolute;
}

/* Remove duplicate styles and ensure container is properly sized */
.company-number-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%; /* Ensure container takes full width */
}

.company-number-container .form-control {
  width: 100%; /* Ensure input takes full width */
}

/* Profile image controls styling */
.profile-image-controls {
  position: relative;
}

.profile-image-controls .img-thumbnail {
  transition: filter 0.3s ease;
}

/* Style the delete button */
.profile-image-controls .btn-danger {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(220, 53, 69, 0.9);
  border: 2px solid white;
  transition: transform 0.2s ease;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Active/Hover states for non-touch devices */
@media (hover: hover) {
  .profile-image-controls .img-thumbnail {
    transition: filter 0.3s ease;
  }

  .profile-image-controls:hover .img-thumbnail {
    filter: brightness(0.9);
  }

  .profile-image-controls .btn-danger {
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.2s ease;
  }

  .profile-image-controls:hover .btn-danger {
    opacity: 1;
  }
}

/* Touch device specific styles */
@media (hover: none) {
  .profile-image-controls .btn-danger {
    opacity: 1;
    background-color: rgb(220, 53, 69);
  }

  .profile-image-controls .btn-danger:active {
    transform: scale(0.95);
  }
}

/* Common hover/active styles */
.profile-image-controls .btn-danger:hover,
.profile-image-controls .btn-danger:active {
  transform: scale(1.1);
  background-color: rgb(220, 53, 69);
}

.profile-image-controls .btn-danger svg {
  width: 16px;
  height: 16px;
}

/* Image preview container */
.profile-image-preview {
  position: relative;
  display: inline-block;
  border-radius: var(--border-radius);
  overflow: hidden;
  margin-bottom: 1rem;
}

.profile-image-preview img {
  display: block;
  max-width: 200px;
  height: auto;
  border-radius: var(--border-radius);
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .profile-image-preview {
    display: block;
    width: 100%;
  }

  .profile-image-preview img {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
  }

  .profile-image-controls .btn-danger {
    width: 40px;
    height: 40px;
  }

  .profile-image-controls .btn-danger svg {
    width: 20px;
    height: 20px;
  }
}

/* Update dropdown item styles for keyboard navigation */
.dropdown-menu-company-number .dropdown-item.active {
  background-color: var(--primary-color);
  color: white;
}

.dropdown-menu-company-number .dropdown-item.active strong {
  color: white;
}

.dropdown-menu-company-number .dropdown-item.active small {
  color: rgba(255, 255, 255, 0.8);
}

/* Ensure focus styles match active styles */
.dropdown-menu-company-number .dropdown-item:focus {
  background-color: var(--primary-color);
  color: white;
  outline: none;
}

.dropdown-menu-company-number .dropdown-item:focus strong {
  color: white;
}

.dropdown-menu-company-number .dropdown-item:focus small {
  color: rgba(255, 255, 255, 0.8);
}





