.container {
    max-width: 1050px;
    margin: 0 auto;
    padding: 0 var(--space-4);
  }
  
  .hero {
    background-color: var(--primary-color);
    padding: var(--space-16) var(--space-4);
    margin-bottom: var(--space-16);
    margin-top: var(--space-8);
  }
  
  .heroContent {
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
  }
  
  .heroContent h1 {
    font-size: var(--font-size-4xl);
    margin-bottom: var(--space-4);
    color: white;
    font-weight: var(--text-weight-semibold);
  }
  
  .heroContent p {
    font-size: var(--font-size-xl);
    margin-bottom: var(--space-8);
    color: white;
    opacity: 0.9;
  }
  
  .howItWorks {
    padding: var(--space-16) var(--space-4);
  }
  
  .howItWorks h2 {
    text-align: left;
    margin-bottom: var(--space-12);
    font-size: var(--font-size-3xl);
    color: var(--primary-color);
  }
  
  .steps {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--space-8);
  }
  
  .step {
    text-align: left;
    padding: var(--space-6);
    background: white;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-light);
  }
  
  .step h3 {
    font-size: var(--font-size-xl);
    margin-bottom: var(--space-4);
    color: var(--primary-color);
  }
  
  .step p {
    color: var(--text-gray);
    line-height: var(--line-height-relaxed);
    font-size: var(--font-size-base);
  }
  
  @media (max-width: 768px) {
    .steps {
      grid-template-columns: 1fr;
      gap: var(--space-4);
    }
  
    .heroContent h1 {
      font-size: var(--font-size-3xl);
    }
  
    .heroContent p {
      font-size: var(--font-size-lg);
    }
  }