.company-marker {
    background-color: white;
    color: black;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    white-space: nowrap;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #eee;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: all 0.2s ease;
    z-index: 1;
  }
  
  /* Hover effect */
  .company-marker:hover {
    background-color: var(--primary-color);
    color: white;
    transform: scale(1.05);
    z-index: 2; /* Bring hovered marker to front */
  }
  
  /* Add this to the mapboxgl-marker class to help with overlapping */
  .mapboxgl-marker {
    will-change: transform;
    transform-origin: bottom center;
    transition: transform 0.1s ease-out;
  }
  
  /* Stagger markers vertically when they overlap */
  .mapboxgl-marker:nth-child(even) {
    margin-top: -15px;
  }
  
  .mapboxgl-marker:nth-child(3n) {
    margin-top: 15px;
  }
  
  .project-icon-marker {
    width: 70px;
    height: 70px;
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--primary-color);
    border-radius: 50%;
    box-shadow: var(--shadow-medium);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }
  
  /* Specific styles for Vattenfall icon - reduce padding to make logo bigger */
  .project-icon-marker.vattenfall-icon {
    padding: 8px; /* Reduced padding to make the logo larger within the circle */
  }
  
  .project-icon-marker img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: brightness(0) invert(1);
  }
  
  .project-icon-marker.fallback-icon {
    width: 5px;
    height: 5px;
  }
  
  /* Add styles for static map container */
  .static-map-container {
    width: 100%;
    height: 400px;
    overflow: hidden;
    position: relative;
  }
  
  @media (max-width: 768px) {
    .static-map-container {
      height: 300px;
    }
  }
  
  /* Project header specific map styles - include mapboxgl-canary */
  .project-header-container .project-header-map .mapboxgl-map,
  .project-header-container .project-header-map .mapboxgl-canvas-container,
  .project-header-container .project-header-map .mapboxgl-canvas,
  .project-header-container .project-header-map .mapboxgl-canary {
    border-radius: 0 0 var(--border-radius-medium) var(--border-radius-medium) 0 !important;
    height: 100%;
  }
  
  /* Adjust border radius for mobile stacked view - include mapboxgl-canary */
  @media (max-width: 768px) {
    .project-header-container .project-header-map .mapboxgl-map,
    .project-header-container .project-header-map .mapboxgl-canvas-container,
    .project-header-container .project-header-map .mapboxgl-canvas,
    .project-header-container .project-header-map .mapboxgl-canary {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      border-bottom-left-radius: var(--border-radius-medium) !important;
      border-bottom-right-radius: var(--border-radius-medium) !important;
      overflow: hidden !important;
    }
  }
  
  /* Mobile styles for different page types */
  @media (max-width: 768px) {
    /* ONLY SearchPage full-screen map styles */
    .search-page .search-page-map.map-view .mapboxgl-map {
      position: fixed !important;
      width: 100% !important;
      height: 100% !important;
      top: 0;
      left: 0;
      touch-action: auto !important;
    }
  
    .search-page .search-page-map.map-view .mapboxgl-canvas {
      width: 100% !important;
      height: 100% !important;
      touch-action: auto !important;
    }
  
    .search-page .search-page-map.map-view .mapboxgl-canvas-container {
      width: 100% !important;
      height: 100% !important;
      touch-action: auto !important;
    }
  
    /* ProfilePage map styles */
    .profile-container .mapboxgl-map,
    .profile-container .mapboxgl-canvas,
    .profile-container .mapboxgl-canvas-container {
      height: 300px !important;
      position: relative !important;
      width: 100% !important;
    }
  
    /* ProjectPage map styles */
    .project-page .mapboxgl-map,
    .project-page .mapboxgl-canvas,
    .project-page .mapboxgl-canvas-container {
      height: 300px !important;
      position: relative !important;
      width: 100% !important;
    }
  
    /* Static map container styles */
    .static-map-container {
      height: 300px;
    }
  }
  
  /* Add new profile-specific map styles */
  .profile-map {
    height: 200px;
    border-radius: var(--border-radius-medium);
    overflow: hidden;
    pointer-events: none; /* Disable map interactions by default */
  }
  
  /* Only enable pointer events for zoom controls */
  .profile-map .mapboxgl-control-container {
  }
  
  /* Hide unnecessary controls for profile map */
  .profile-map .mapboxgl-ctrl-group > button:not(.mapboxgl-ctrl-zoom-in):not(.mapboxgl-ctrl-zoom-out) {
    display: none;
  }
  
  /* Ensure map and its children maintain border radius */
  .profile-map .mapboxgl-map,
  .profile-map .mapboxgl-canvas-container,
  .profile-map .mapboxgl-canvas,
  .profile-map .mapboxgl-canary {
    border-radius: var(--border-radius-medium) !important;
  }
  
  /* Mobile adjustments for profile map */
  @media (max-width: 768px) {
    .profile-map {
      height: 300px;
    }
  }
  
  /* Add these new styles */
  .cluster-popup {
    padding: 10px;
    max-width: 200px;
  }
  
  .cluster-popup h4 {
    margin: 0 0 8px 0;
    font-size: 14px;
  }
  
  .cluster-popup button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    width: 100%;
  }
  
  .cluster-popup button:hover {
    opacity: 0.9;
  }
  
  /* Cluster circles */
  .mapboxgl-canvas-container .cluster-marker {
    background-color: var(--primary-color);
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  
  .mapboxgl-canvas-container .cluster-marker:hover {
    transform: scale(1.1);
  }
  
  /* Ensure the map container has hardware acceleration and proper cursor */
  .map-container {
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
    cursor: default !important;
  }
  
  /* Add cursor styles for map and canvas */
  .mapboxgl-map {
    cursor: default !important;
  }
  
  .mapboxgl-canvas {
    cursor: default !important;
  }
  
  /* Scope cursor styles to specific map types */
  .static-map-container .mapboxgl-map,
  .static-map-container .mapboxgl-canvas,
  .search-page-map .mapboxgl-map,
  .search-page-map .mapboxgl-canvas {
    cursor: default !important;
  }
  
  /* Remove the previous generic cursor styles */
  /* .map-container,
  .mapboxgl-map,
  .mapboxgl-canvas {
    cursor: default !important;
  } */
  
  .center-windmill-marker {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
  }
  

  .mapboxgl-popup-content {
    font-family: var(--font-family-base);
    font-size: var(--text-base);
    font-weight: var(--text-weight-medium);
    padding: calc(var(--spacing-unit) * 1.5);
    border-radius: 8px;
    box-shadow: var(--shadow-medium);
    border: 1px solid var(--border-color);
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .mapboxgl-popup-tip {
    border-top-color: var(--background-color) !important;
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.1));
  }