.project-page {
  max-width: 1050px;
  margin: 0 auto;
  padding: 20px;
}

/* Add styles for the logo container */
.project-logo-container {
  width: 120px;
  height: 120px;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -60px auto 20px;
  position: relative;
  z-index: 2;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-logo-container img {
  width: 80%;
  height: 80%;
  object-fit: contain;
  filter: brightness(0) invert(1); /* Makes the logo white */
}

@media (max-width: 768px) {
  .project-page {
    padding: 0; /* Remove padding on mobile */
  }
  
  /* Ensure content inside maintains some spacing */
  .project-page > * {
    padding: 15px;
  }

  /* Remove top padding from header to ensure corners are straight */
  .project-header-container {
    padding-top: 0;
  }

  /* Adjust logo size for mobile */
  .project-logo-container {
    width: 90px;
    height: 90px;
    margin: -45px auto 15px;
    padding: 15px;
  }
}

/* Add more global styles as needed */

