/* Base container */
.project-page {
    max-width: 1050px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Animation */
  .skeleton-pulse {
    animation: pulse 1.5s infinite;
    background: #e0e0e0;
  }
  
  @keyframes pulse {
    0% { opacity: .6; }
    50% { opacity: .3; }
    100% { opacity: .6; }
  }
  
  /* Header Section */
  .project-header-skeleton {
    position: relative;
    margin-bottom: 40px;
  }
  
  .skeleton-banner {
    width: 100%;
    height: 300px;
    border-radius: 8px;
  }
  
  .project-logo-container-skeleton {
    width: 120px;
    height: 120px;
    margin: -60px auto 20px;
    position: relative;
    z-index: 2;
  }
  
  .skeleton-logo {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  
  .skeleton-header-content {
    text-align: center;
    padding: 0 20px;
  }
  
  .skeleton-title {
    height: 36px;
    width: 60%;
    margin: 0 auto 16px;
    border-radius: 4px;
  }
  
  .skeleton-subtitle {
    height: 24px;
    width: 80%;
    margin: 0 auto;
    border-radius: 4px;
  }
  
  /* Info Section */
  .project-info-skeleton {
    padding: 20px;
  }
  
  .skeleton-section {
    margin-bottom: 40px;
  }
  
  .skeleton-section-title {
    height: 28px;
    width: 30%;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  
  .skeleton-text-block {
    height: 16px;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 12px;
  }
  
  .skeleton-text-block:last-child {
    width: 80%;
  }
  
  .skeleton-status {
    height: 32px;
    width: 120px;
    border-radius: 16px;
  }
  
  .skeleton-map {
    height: 300px;
    border-radius: 8px;
    margin-bottom: 16px;
  }
  
  .skeleton-location-text {
    height: 20px;
    width: 40%;
    border-radius: 4px;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .project-page {
      padding: 0;
    }
  
    .project-header-skeleton,
    .project-info-skeleton {
      padding: 15px;
    }
  
    .skeleton-banner {
      height: 200px;
      border-radius: 0;
    }
  
    .project-logo-container-skeleton {
      width: 90px;
      height: 90px;
      margin: -45px auto 15px;
    }
  
    .skeleton-title {
      height: 28px;
      width: 80%;
    }
  
    .skeleton-subtitle {
      height: 20px;
      width: 90%;
    }
  
    .skeleton-map {
      height: 200px;
    }
  
    .skeleton-section-title {
      width: 50%;
    }
  }