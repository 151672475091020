.faq-page {
  padding: 2rem 1rem;
}

.faq-container {
  max-width: 1050px;
  margin: 0 auto;
}

.accordion-button:not(.collapsed) {
  background-color: var(--primary-color);
  color: white;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
}

.accordion-button,
.accordion-body {
  font-family: var(--font-family-base);
  font-weight: var(--text-weight-normal);
  letter-spacing: -0.1px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
}

