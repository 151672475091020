/* Login Page Styles */
.form-container {
  width: 100%;
  margin: 0;
}

/* Typography */
.header-title {
  display: none;
}

.main-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 32px;
}

/* Form Elements */
.login-form .form-label {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 8px;
  display: block;
}

.input-group-bottom-border {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 12px 16px;
  margin-bottom: 24px;
  border: 1px solid #DDDDDD;
}

.input-group-bottom-border .form-control {
  border: none;
  padding: 0;
  font-size: 16px;
  background: transparent;
  box-shadow: none !important;
  margin: 0;
  height: auto;
  width: 100%;
}

.input-group-bottom-border .input-group-text {
  background: transparent;
  border: none;
  padding: 0;
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.email-icon {
  color: #2D3436;
  font-size: 20px;
}

/* Remove Bootstrap's default styles */
.form-control:focus {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}

.input-group-bottom-border:focus-within {
  border-color: var(--primary-color);
}

/* Remove any Bootstrap margins */
.mb-0 {
  margin-bottom: 0 !important;
}

.form-control.mb-0 {
  margin-bottom: 0 !important;
}

/* Terms and Links */
.terms-container {
  margin-bottom: 24px;
  font-size: 14px;
  color: var(--text-gray);
}

.terms-container button,
.toggle-button {
  color: #1a73e8;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-color 0.2s ease;
  padding: 0;
  margin: 0 0 0 4px;
  background: none;
  border: none;
  font-size: inherit;
  box-shadow: none !important;
  width: auto !important;
  height: auto !important;
  display: inline !important;
  min-width: 0 !important;
  max-width: none !important;
}

.terms-container button:hover,
.toggle-button:hover {
  border-bottom-color: #1a73e8;
  background: none !important;
  box-shadow: none !important;
  color: #1a73e8 !important;
}

/* Override any Bootstrap button styles */
.terms-container button.btn,
.toggle-button.btn {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 0 0 4px !important;
}

/* Submit Button */
.submit-button {
  height: 50px;
  border-radius: 4px;
  font-weight: 500;
  margin-bottom: 24px;
}

/* Toggle Container */
.toggle-container {
  text-align: center;
  font-size: 14px;
  color: var(--text-gray);
}

/* Close Button */
.btn-close {
  opacity: 0.5;
  transition: opacity 0.2s ease;
}

.btn-close:hover {
  opacity: 0.75;
}

/* Success State */
.success-animation {
  display: flex;
  justify-content: center;
  margin: 24px 0;
}

.success-animation .text-primary {
  color: var(--success-color) !important;
}

.steps-container {
  display: block;
  margin-top: 24px;
  background: var(--light-bg);
  padding: 16px;
  border-radius: 8px;
}

.steps-container h6 {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color);
  margin-bottom: 12px;
}

.steps-container ol {
  margin: 0;
  padding-left: 20px;
}

.steps-container li {
  font-size: 14px;
  color: var(--text-gray);
  margin-bottom: 8px;
  line-height: 1.4;
}

.steps-container li:last-child {
  margin-bottom: 0;
}

/* Alert styling */
.alert-warning {
  background-color: var(--light-warning);
  border: 1px solid var(--warning-color);
  color: var(--text-color);
  margin: 24px 0 0 0;
}

.alert-warning .btn-link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
}

.alert-warning .btn-link:hover {
  text-decoration: underline;
}

/* Alert Styles */
.alert {
  border: none;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.alert-warning {
  background-color: var(--warning-color);
  color: white;
}

.alert-success {
  background-color: var(--success-color);
  color: white;
}

/* Login Modal specific styles */
.login-modal .modal-header {
  padding: 16px 20px;
  border-bottom: 1px solid var(--light-gray-1);
}

.login-modal .modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

.login-modal .modal-body {
  padding: 16px 20px;
}

.login-modal .success-state {
  text-align: left;
  padding: 0;
}

.login-modal .success-icon {
  color: var(--success-color);
  font-size: 48px;
  margin-bottom: 24px;
}

.login-modal .success-message {
  font-size: 16px;
  line-height: 1.5;
  color: var(--text-color);
  margin-bottom: 16px;
}

.login-modal .success-message p {
  margin: 0 0 16px 0;
}

.login-modal .email-highlight {
  display: block;
  font-weight: 500;
  color: var(--primary-color);
  margin: 8px 0;
}

.login-modal .steps-container {
  background: var(--secondary-color);
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
}

.login-modal .steps-container h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  color: var(--text-color);
}

.login-modal .steps-container ol {
  margin: 0;
  padding-left: 20px;
}

.login-modal .steps-container li {
  font-size: 14px;
  color: var(--text-gray);
  margin-bottom: 8px;
  line-height: 1.4;
}

.login-modal .steps-container li:last-child {
  margin-bottom: 0;
}

.login-modal .alert-warning {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #fff3e0;
  border: none;
  border-radius: 8px;
  padding: 12px 16px;
  margin: 16px 0 0 0;
  color: var(--text-color);
}

.login-modal .alert-warning .warning-icon {
  color: #f57c00;
  font-size: 20px !important;
}

.login-modal .alert-warning small {
  color: var(--text-color);
  line-height: 1.4;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .login-modal .modal-header {
    padding: 16px 20px;
  }
  
  .login-modal .modal-body {
    padding: 0 20px 20px;
  }
  
  .login-modal .steps-container {
    margin: 20px 0;
    padding: 16px;
  }
}

/* Inline link style */
.inline-link {
  color: var(--link-color);
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
}

.inline-link:hover {
  text-decoration: underline;
  color: var(--link-hover-color);
}

/* Login success state */
.success-state {
  padding: 0 20px;
}

.success-message {
  color: var(--text-color);
}

.email-icon-wrapper {
  margin-bottom: 16px;
}

.email-icon {
  font-size: 24px;
  color: var(--text-color);
}

.waiting-text {
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 32px;
}

.email-text {
  display: block;
  margin-top: 8px;
  font-weight: 500;
}

/* Steps section */
.next-steps {
  margin-bottom: 24px;
}

.next-steps h6 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 12px;
}

.next-steps ol {
  margin: 0;
  padding-left: 20px;
}

.next-steps li {
  font-size: 14px;
  color: var(--text-gray);
  margin-bottom: 8px;
  line-height: 1.4;
}

.next-steps li:last-child {
  margin-bottom: 0;
}

/* Help text */
.help-text {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 12px 16px;
  background-color: #fff3e0;
  border-radius: 8px;
  font-size: 14px;
  color: var(--text-color);
}

.warning-icon {
  flex-shrink: 0;
  font-size: 20px;
  color: #f57c00;
}

@media (max-width: 768px) {
  .success-state {
    padding: 0 16px;
  }

  .alert-success {
    font-size: 13px;
    padding: 10px 14px;
    margin-bottom: 20px;
  }

  .waiting-text {
    font-size: 14px;
    margin-bottom: 24px;
  }

  .next-steps h6 {
    font-size: 14px;
  }

  .next-steps li {
    font-size: 13px;
  }

  .help-text {
    font-size: 13px;
    padding: 10px 14px;
  }
}

/* Add this at the top of the file */
.login-page {
  margin: 64px auto;
  max-width: 400px;
  width: 100%;
  padding: 0 20px;
}
