.terms-page {
    max-width: 1050px;
    margin: 0 auto;
    padding: 2rem 1rem;
  }
  
  .terms-container {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .terms-content {
    line-height: 1.6;
  }
  
  .terms-content h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  
  .terms-content p {
    margin-bottom: 1rem;
  }