/* src/components/SearchPage/SearchPage.css */

/* Main Layout */
.search-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  overflow: hidden;
}

.content {
  display: flex;
  flex: 1;
  overflow: hidden;
  max-width: 100%;
  height: 100%;
}

/* Left Panel */
.left-panel {
  width: 66.66%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-right: 1px solid #e0e0e0;
}

/* Cards Container - Desktop */
.cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
  overflow-y: auto;
  height: 100%;
}

.cards-container > div {
  height: 300px !important;
  display: flex;
  flex-direction: column;
}

.cards-container > div > * {
  height: 100%;
  flex: 1;
}

/* Map Container */
.map-container {
  width: 33.33%;
  height: 100%;
  flex: 0 0 33.33%;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.map-container > div,
.map-container .mapboxgl-map {
  height: 100% !important;
  width: 100% !important;
}

/* Tag Filter Container */
.tag-filters {
  display: flex;
  gap: 10px;
  padding: 15px 20px;
  border-bottom: 1px solid #e0e0e0;
  overflow-x: auto;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tag-filters::-webkit-scrollbar {
  display: none;
}

/* Tag Filter Button */
.tag-filter {
  padding: 8px 16px;
  border: 1px solid var(--accent-color);
  border-radius: var(--border-radius-pill);
  background: transparent;
  color: var(--accent-color);
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: var(--font-family-base);
  font-size: var(--text-small);
  font-weight: var(--text-weight-medium);
  white-space: nowrap;
  flex-shrink: 0;
}

.tag-filter:hover {
  background: rgba(41, 83, 118, 0.1);
}

.tag-filter.active {
  background: var(--accent-color);
  color: white;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .search-page {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding-top: 80px;
    overflow: hidden;
    z-index: 1;
    background: white;
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    height: calc(100% - 80px);
    overflow: hidden;
  }

  .map-container {
    position: relative !important;
    height: 55% !important;
    min-height: 150px !important;
    width: 100% !important;
    flex: 0 0 auto;
  }

  .left-panel {
    position: relative;
    width: 100%;
    height: 45% !important;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .tag-filters {
    padding: 4px 8px;
    gap: 4px;
    height: 36px;
    min-height: 36px;
    flex-shrink: 0;
  }

  .tag-filter {
    padding: 4px 8px;
    font-size: 11px;
    height: 24px;
    min-height: 24px;
    display: flex;
    align-items: center;
  }

  .cards-container {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    gap: 35px !important;
    padding: 12px !important;
    scroll-snap-type: x mandatory !important;
    -webkit-overflow-scrolling: touch !important;
    height: calc(100% - 36px) !important;
    max-height: calc(65vh - 36px) !important;
  }

  .cards-container > div {
    flex: 0 0 280px !important;
    width: 280px !important;
    min-width: 280px !important;
    height: 150px !important;
    scroll-snap-align: start !important;
    margin-right: 0 !important;
    transition: all 0.3s ease;
  }

  .card-wrapper {
    transition: all 0.3s ease;
  }

  .card-wrapper.snapped {
    transform: translateY(-4px);
  }

  .card-wrapper.snapped .card-title > span {
    color: var(--link-color) !important;
  }

  .cards-container {
    grid-template-columns: none !important;
  }

  .cards-container::-webkit-scrollbar {
    display: none !important;
  }

  .cards-container {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
  }
}

/* Responsive grid layouts */
@media (max-width: 2200px) and (min-width: 1500px) {
  .cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1500px) and (min-width: 769px) {
  .cards-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Adjust desktop layout for slim screens */
@media (max-width: 1200px) and (min-width: 769px) {
  .content {
    flex-direction: column-reverse;
    height: calc(100vh - 80px);
  }

  .left-panel {
    width: 100%;
    height: 60%;
    border-right: none;
    border-top: 1px solid #e0e0e0;
  }

  .map-container {
    width: 100%;
    height: 40%;
    flex: 0 0 40%;
  }

  .cards-container {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 16px;
    padding: 16px;
  }
}

/* Wider screens */
@media (min-width: 1201px) {
  .cards-container {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}



