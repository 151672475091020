.cookie-policy {
  padding: 2rem 0;
}

.cookie-policy h1 {
  margin-bottom: 2rem;
}

.cookie-policy section {
  margin-bottom: 3rem;
}

.cookie-policy h2 {
  margin-bottom: 1.5rem;
}

.cookie-policy h3 {
  margin: 1.5rem 0 1rem;
  font-size: 1.2rem;
}

.consent-status {
  margin-top: 2rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.consent-status p {
  margin-bottom: 1rem;
} 