/* CSS Reset (Optional but recommended for consistency) */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
}

/* Add this at the top of the file, after the CSS Reset */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap');

/* Global text styles */
p, span, div {
  font-family: "Poppins", Helvetica, Arial, sans-serif !important;
  font-weight: 400;
  letter-spacing: -0.1px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
}

/* Ensure form elements inherit the font */
input,
button,
textarea,
select {
  font-family: "Poppins", Helvetica, Arial, sans-serif !important;
}

/* Flexbox and layout rules */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

.footer {
  position: relative;
}

/* Global color variables */
:root {
  /* Colors */
  --primary-color: #0D243A;
  --secondary-color: #EFF9F6;
  --accent-color: #295376;
  --text-color: #2D3436;  /* Dark grey instead of pure black */
  --background-color: #FCFCFC;
  --text-light-gray: rgb(165, 163, 163);
  --text-gray: rgb(55, 55, 55);
  --light-gray-1: rgb(206, 205, 205);
  --warning-orange: rgb(255, 183, 68);
  --link-color: #1a73e8;
  --link-hover-color: #1558b0;

  /* Spacing */
  --spacing-unit: 8px;

  /* Border Radius */
  --border-radius-small: 4px;
  --border-radius-medium: 15px;
  --border-radius-large: 16px;
  --border-radius-circle: 50%;
  --border-radius-pill: 9999px;
  --border-radius: var(--border-radius-medium); /* Default border radius */

  /* Shadows */
  --shadow-light: 0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.05);
  --shadow-medium: 0px 4px 10px rgba(0, 0, 0, 0.12), 0px 3px 8px rgba(0, 0, 0, 0.05);
  --shadow-large: 0px 10px 30px rgba(0, 0, 0, 0.14), 0px 8px 12px rgba(0, 0, 0, 0.05);
  --shadow-hover: 0px 14px 24px rgba(0, 0, 0, 0.16), 0px 10px 12px rgba(0, 0, 0, 0.15);


    /* Typography */
    --font-family-base: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --text-small: 0.875rem;
    --text-base: 1rem;
    --text-large: 1.125rem;
    --text-xl: 1.25rem;
    --text-2xl: 1.5rem;
    --text-3xl: 1.875rem;
    --text-4xl: 2.25rem;
    --text-weight-normal: 400;
    --text-weight-medium: 500;
    --text-weight-semibold: 600;
    --text-weight-bold: 700;
    --line-height-tight: 1.25;
    --line-height-normal: 1.5;
    --line-height-relaxed: 1.75;

    /* Typography Scale */
    --font-size-xs: 0.75rem;    /* 12px */
    --font-size-sm: 0.875rem;   /* 14px */
    --font-size-base: 1rem;     /* 16px */
    --font-size-lg: 1.125rem;   /* 18px */
    --font-size-xl: 1.25rem;    /* 20px */
    --font-size-2xl: 1.5rem;    /* 24px */
    --font-size-3xl: 2rem;      /* 32px */
    --font-size-4xl: 2.5rem;    /* 40px */

    /* Line Heights */
    --line-height-tight: 1.25;
    --line-height-normal: 1.5;
    --line-height-relaxed: 1.75;

    /* Spacing Scale */
    --space-1: 0.25rem;   /* 4px */
    --space-2: 0.5rem;    /* 8px */
    --space-3: 0.75rem;   /* 12px */
    --space-4: 1rem;      /* 16px */
    --space-6: 1.5rem;    /* 24px */
    --space-8: 2rem;      /* 32px */
    --space-12: 3rem;     /* 48px */
    --space-16: 4rem;     /* 64px */

    /* Component-specific variables */
    --header-height: 80px;
    --sidebar-width: 250px;
    --card-padding: var(--space-4);
    --input-height: 40px;
}

/* Global font and body styles */
body {
  font-family: var(--font-family-base);
  font-weight: var(--text-weight-normal);
  color: var(--text-color);
  background-color: var(--background-color);
  line-height: var(--line-height-normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

/* Heading styles */
h1, h2, h3, h4, h5, h6 {
  font-weight: var(--text-weight-semibold);
  color: var(--primary-color);
  line-height: var(--line-height-tight);
  margin-bottom: calc(var(--spacing-unit) * 1.5);
}

/* Font sizes */

h1 { font-size: var(--text-4xl); }
h2 { font-size: var(--text-3xl); }
h3 { font-size: var(--text-2xl); }
h4 { font-size: var(--text-xl); }
h5 { font-size: var(--text-large); }
h6 { font-size: var(--text-base); }

/* Paragraph styles */
p {
  margin-bottom: calc(var(--spacing-unit) * 1);
  color: var(--text-color);
  font-weight: 500;  /* Matching the body weight */
}

/* Link styles */
a {
  color: var(--link-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--link-hover-color);
  text-decoration: underline;
}

/* Global Button Styles */
button, .btn {
  padding: calc(var(--spacing-unit) * 1.5) calc(var(--spacing-unit) * 2); /* Increased height via padding */
  font-size: 1rem;
  border-radius: var(--border-radius-small); /* Changed to small border radius */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: var(--shadow-light); /* Added shadow */
}

/* Primary Button */
.btn-primary, button.btn-primary {
  background-color: var(--primary-color);
  color: #fff;
  max-width: 400px;  /* Add max-width */
  width: 100%;       /* Add width to ensure it fills container up to max-width */
  border-radius: var(--border-radius-small) !important;
  height: 50px;
  text-decoration: none;
}

.btn-primary:hover, button.btn-primary:hover {
  background-color: #0a1b2a;
  transform: translateY(-1px); /* Changed from scale to a subtle lift effect */
  box-shadow: var(--shadow-medium);
  text-decoration: none;

}

/* Secondary Button */
.btn-secondary, button.btn-secondary {
  background-color: var(--secondary-color);
  color: var(--text-color);
  border-radius: var(--border-radius-small) !important;
  height: 50px;

}

.btn-secondary:hover, button.btn-secondary:hover {
  background-color: var(--secondary-color);
  transform: translateY(-1px); /* Changed from scale to a subtle lift effect */
  box-shadow: var(--shadow-medium);
  color: var(--text-color);
  text-decoration: none;

}

/* Accent Button */
.btn-accent, button.btn-accent {
  background-color: var(--accent-color);
  color: #fff;
  border-radius: var(--border-radius-small) !important;
  height: 50px;

}

.btn-accent:hover, button.btn-accent:hover {
  background-color: #1f3f5a; /* Manually adjusted darker shade */
  transform: translateY(-1px); /* Changed from scale to a subtle lift effect */
  box-shadow: var(--shadow-medium);
  border-radius: var(--border-radius-small) !important;
  height: 50px;

}

/* Warning Button */
.btn-warning, button.btn-warning {
  background-color: var(--warning-orange);
  color: #fff;
  border: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-radius: var(--border-radius-small) !important;
  height: 50px;

}

.btn-warning:hover, button.btn-warning:hover {
  background-color: rgb(230, 160, 50); /* Manually adjusted darker shade */
  transform: translateY(-1px); /* Changed from scale to a subtle lift effect */
  box-shadow: var(--shadow-medium);
  border-radius: var(--border-radius-small) !important;
  height: 50px;

}

/* Form Controls - Simplified */
.form-control {
  height: 50px;
  padding: 0 16px;
  border: none;
  border-radius: 4px;
  font-family: var(--font-family-base);
  font-size: 16px;
  color: var(--text-color);
  width: 100%;
  background-color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-control:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: none;
}

/* Override any browser default styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* Select Element */
select.form-control {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%232D3436' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 16px center;
  padding-right: 48px;
}

/* Form Groups */
.form-group {
  margin-bottom: 24px;
}

/* Form Labels */
.form-label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #2D3436;
}

/* Placeholder Text */
.form-control::placeholder {
  color: #757575;
  opacity: 1;
}

/* List Styles */
ul, ol {
  margin-left: calc(var(--spacing-unit) * 2);
  margin-bottom: calc(var(--spacing-unit) * 1);
}

li {
  margin-bottom: calc(var(--spacing-unit) * 0.5);
}

/* Image Styles */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Table Styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: calc(var(--spacing-unit) * 2);
}

th, td {
  padding: calc(var(--spacing-unit) * 1);
  text-align: left;
  border-bottom: 1px solid var(--light-gray);
}

/* Button Focus Styles for Accessibility */
button:focus, .btn:focus,
input:focus, .form-control:focus,
select:focus, textarea:focus {
  outline: 2px solid var(--accent-color);
  outline-offset: 2px;
}

/* Utility Classes */

/* Text Align */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

/* Margin and Padding */
.mt-1 { margin-top: calc(var(--spacing-unit) * 1); }
.mb-1 { margin-bottom: calc(var(--spacing-unit) * 1); }
.pt-1 { padding-top: calc(var(--spacing-unit) * 1); }
.pb-1 { padding-bottom: calc(var(--spacing-unit) * 1); }

/* Display */
.d-flex {
  display: flex;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Visibility */
.hidden {
  display: none;
}

/* Additional Components */

/* Card Component */
.card {
  background-color: #fff;
  border: 1px solid var(--light-gray);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-light);
  padding: calc(var(--spacing-unit) * 2);
  margin-bottom: calc(var(--spacing-unit) * 2);
}

/* Navbar */
.navbar {
  background-color: var(--primary-color);
  color: #fff;
  padding: calc(var(--spacing-unit) * 1);
}

.navbar a {
  color: #fff;
  margin-right: calc(var(--spacing-unit) * 2);
}

.navbar a:hover {
  text-decoration: underline;
}

/* Footer */
.footer {
  background-color: var(--secondary-color);
  color: var(--text-color);
  padding: calc(var(--spacing-unit) * 2);
  text-align: center;
}



.offer-group {
  padding: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  background-color: #f8f9fa;
}

.offer-group + .offer-group {
  margin-top: 1rem;
}

.cookie-notice {
  font-family: var(--font-family-base);
  font-weight: var(--text-weight-normal);
  font-size: var(--text-small);
  color: var(--text-color);
  line-height: var(--line-height-normal);
}

/* Layout & Container Improvements */
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* Sidebar Navigation */
.sidebar {
  position: fixed;
  top: 80px; /* Account for header height */
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  background-color: var(--background-color);
  overflow-y: auto;
}

.sidebar .nav-link {
  font-weight: 500;
  color: var(--text-color);
  padding: 0.5rem 1rem;
  border-left: 3px solid transparent;
}

.sidebar .nav-link:hover,
.sidebar .nav-link.active {
  color: var(--primary-color);
  border-left-color: var(--primary-color);
  background-color: rgba(21, 85, 121, 0.05);
}

/* Component Display Improvements */
.component-grid {
  display: grid;
  gap: 2rem;
  padding: 1.5rem;
  background: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--light-gray-1);
  margin-bottom: 2rem;
}

.example-item {
  padding: 1.5rem;
  border: 1px solid var(--light-gray-1);
  border-radius: var(--border-radius);
  background: white;
}

/* Code Display */
.code-example {
  position: relative;
  margin: 1rem 0;
}

.code-example code {
  display: block;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: var(--border-radius);
  font-family: monospace;
  font-size: 0.875rem;
  color: var(--text-color);
  overflow-x: auto;
}

.copy-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.25rem 0.5rem;
  background: white;
  border: 1px solid var(--light-gray-1);
  border-radius: var(--border-radius-small);
  font-size: 0.75rem;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.code-example:hover .copy-button {
  opacity: 1;
}

/* Typography Improvements */
.section-title {
  font-size: var(--text-2xl);
  font-weight: var(--text-weight-semibold);
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid var(--light-gray-1);
}

/* Component Status Indicators */
.component-status {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: var(--border-radius-pill);
  font-size: var(--text-small);
  font-weight: var(--text-weight-medium);
  margin-left: 0.5rem;
}

.status-stable {
  background-color: var(--success-color-light);
  color: var(--success-color);
}

.status-beta {
  background-color: var(--warning-orange);
  color: white;
}

.status-deprecated {
  background-color: var(--fail-color-light);
  color: var(--fail-color);
}

/* Usage Guidelines */
.usage-guidelines {
  background: white;
  border-radius: var(--border-radius);
  padding: 1.5rem;
  margin: 1.5rem 0;
  border: 1px solid var(--light-gray-1);
}

.usage-guidelines li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

/* Responsive Indicators */
.screen-size-indicator {
  padding: 0.25rem 0.5rem;
  background: var(--primary-color);
  color: white;
  border-radius: var(--border-radius-small);
  font-size: var(--text-small);
  font-weight: var(--text-weight-medium);
  margin-bottom: 1rem;
}

/* Color Palette Display */
.color-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin: 1.5rem 0;
}

.color-item {
  padding: 1rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--light-gray-1);
}

.color-swatch {
  height: 100px;
  border-radius: var(--border-radius-small);
  margin-bottom: 0.5rem;
}

.color-info {
  font-size: var(--text-small);
}

/* Responsive Design */
@media (max-width: 768px) {
  .sidebar {
    position: static;
    padding: 1rem 0;
  }

  .component-grid {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .color-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

/* Theme Toggle Support */
[data-theme="dark"] {
  --background-color: #1a1a1a;
  --text-color: #ffffff;
  --light-gray-1: #333333;
  /* Add more dark theme variables */
}

/* Accessibility Improvements */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Focus States */
:focus-visible {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Interactive States */
.interactive-demo {
  position: relative;
  padding: 1rem;
  border: 1px solid var(--light-gray-1);
  border-radius: var(--border-radius);
  margin: 1rem 0;
}

.interactive-demo::before {
  content: 'Interactive';
  position: absolute;
  top: -0.75rem;
  left: 1rem;
  background: white;
  padding: 0 0.5rem;
  font-size: var(--text-small);
  color: var(--primary-color);
}

/* Button Base Styles */
.btn {
  --btn-height: 50px;
  --btn-padding-x: 24px;
  --btn-font-size: var(--font-size-base);
  --btn-font-weight: 500;
  --btn-border-radius: 4px;

  height: var(--btn-height);
  padding: 0 var(--btn-padding-x);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  border-radius: var(--btn-border-radius) !important;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
  max-width: 400px;
  transform: none !important;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1); /* Smoother transition */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Default shadow */
}

.btn:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Slightly larger shadow on hover */
  transform: none !important;
}

/* Standard Button Variants */
.btn-primary {
  background-color: #0D243A;
  color: white;
}

.btn-primary:hover:not(:disabled) {
  background-color: #0a1b2a;
}

.btn-secondary {
  background-color: #EFF9F6;
  color: #2D3436;
}

.btn-secondary:hover:not(:disabled) {
  background-color: #e6f4f0;
}

.btn-warning {
  background-color: #e9ac32;
  color: white;
}

.btn-warning:hover:not(:disabled) {
  background-color: #d99b21;
}

/* Button Sizes */
.btn-sm {
  --btn-height: 40px;
  --btn-padding-x: 16px;
  --btn-font-size: var(--font-size-sm);
}

.btn-lg {
  --btn-height: 60px;
  --btn-padding-x: 32px;
  --btn-font-size: var(--font-size-lg);
}

/* Button States */
.btn:disabled {
  background-color: #e7e7e7;
  color: #b2b2b2;
  cursor: not-allowed;
  opacity: 1;
  box-shadow: none; /* No shadow for disabled state */
}

.btn.active {
  background-color: #295376;
  color: white;
}

/* Icon Button */
.btn-icon {
  width: auto;
  padding: 0;
  width: var(--btn-height);
  min-width: var(--btn-height);
}

.btn-icon i {
  font-size: 1.2em;
}

/* Button with Icon */
.btn-with-icon {
  gap: 8px;
}

.btn-with-icon i {
  font-size: 1.2em;
}

/* Button Group */
.button-group {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .btn {
    --btn-height: 44px;
    --btn-padding-x: 20px;
  }
  
  .button-group {
    gap: 8px;
  }
}

/* Component Status */
.component-header {
  display: flex;
  align-items: center;
  gap: var(--space-2);
  margin-bottom: var(--space-4);
}

.status-badge {
  padding: var(--space-1) var(--space-2);
  border-radius: var(--border-radius-pill);
  font-size: var(--font-size-xs);
  font-weight: 500;
}

.status-stable { background-color: var(--success-color-light); }
.status-beta { background-color: var(--warning-color-light); }
.status-deprecated { background-color: var(--error-color-light); }

/* Component Documentation */
.component-doc {
  border: 1px solid var(--gray-200);
  border-radius: var(--border-radius);
  margin-bottom: var(--space-8);
}

.component-doc__header {
  padding: var(--space-4);
  border-bottom: 1px solid var(--gray-200);
}

.component-doc__body {
  padding: var(--space-4);
}

.component-doc__footer {
  padding: var(--space-4);
  background-color: var(--gray-50);
  border-top: 1px solid var(--gray-200);
}

.code-preview {
  background-color: var(--gray-50);
  padding: var(--space-4);
  border-radius: var(--border-radius);
  margin: var(--space-4) 0;
}

/* Grid System */
.grid {
  display: grid;
  gap: var(--space-4);
}

.grid-cols-1 { grid-template-columns: repeat(1, 1fr); }
.grid-cols-2 { grid-template-columns: repeat(2, 1fr); }
.grid-cols-3 { grid-template-columns: repeat(3, 1fr); }
.grid-cols-4 { grid-template-columns: repeat(4, 1fr); }

@media (max-width: 768px) {
  .grid { gap: var(--space-3); }
  .grid-cols-2, 
  .grid-cols-3, 
  .grid-cols-4 { 
    grid-template-columns: 1fr; 
  }
}

/* Style Page Specific */
.style-page {
  background-color: var(--background-color);
}

.sidebar-heading {
  padding: 0 1rem;
  margin-bottom: 1rem;
  color: var(--text-color);
}

.style-content {
  max-width: 1050px;
  margin: 0 auto;
  padding: 2rem 0;
}

.style-section {
  margin-bottom: 4rem;
}

.style-section h2 {
  font-size: var(--text-2xl);
  margin-bottom: 2rem;
  color: var(--text-color);
  font-weight: var(--text-weight-semibold);
}

.style-examples {
  background: white;
  padding: 2rem;
  border-radius: var(--border-radius);
  margin-bottom: 2rem;
}

/* Remove excessive borders and simplify layout */
.component-grid {
  display: grid;
  gap: 2rem;
  padding: 0;
  background: transparent;
  border: none;
  margin-bottom: 2rem;
}

.example-item {
  background: white;
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-light);
}

/* Improve sidebar navigation */
.sidebar {
  background: white;
  box-shadow: none;
  border-right: 1px solid var(--light-gray-1);
}

.nav-link {
  cursor: pointer;
  padding: 0.75rem 1rem;
  color: var(--text-color);
  transition: all 0.2s ease;
  border-left: 3px solid transparent;
}

.nav-link:hover,
.nav-link.active {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border-left-color: var(--primary-color);
  text-decoration: none;
}

/* Color grid improvements */
.color-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
  background: transparent;
  border: none;
}

.color-item {
  background: white;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-light);
  overflow: hidden;
}

/* Form group spacing */
.form-group + .form-group {
  margin-top: 1.5rem;
}

/* Remove unnecessary borders and add spacing */
.style-examples > * + * {
  margin-top: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .style-content {
    padding: 1rem;
  }
  
  .style-examples {
    padding: 1rem;
  }
  
  .style-section {
    margin-bottom: 2rem;
  }
}

/* Smooth scrolling for the whole page */
html {
  scroll-behavior: smooth;
}

/* Style Page Navigation */
.style-page {
  scroll-padding-top: 100px; /* Adjust based on your header height */
}

.sidebar {
  position: sticky; /* Change from fixed to sticky */
  top: 100px; /* Adjust based on your header height */
  height: calc(100vh - 100px); /* Adjust based on your header height */
  overflow-y: auto;
  padding: 1rem 0;
  background: white;
  border-right: 1px solid var(--light-gray-1);
}

.nav-link {
  cursor: pointer;
  padding: 0.75rem 1rem;
  color: var(--text-color);
  transition: all 0.2s ease;
  border-left: 3px solid transparent;
  text-decoration: none !important;
}

.nav-link:hover,
.nav-link.active {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  border-left-color: var(--primary-color);
  text-decoration: none !important;
}

/* Ensure sections have proper spacing and padding */
.style-section {
  scroll-margin-top: 100px; /* Adjust based on your header height */
  padding-top: 2rem;
}

/* Add focus styles for accessibility */
.nav-link:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--primary-color);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sidebar {
    position: static;
    height: auto;
    margin-bottom: 2rem;
  }
}

/* Button Groups */
.button-group {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
}

/* Switch styles */
.form-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--light-gray-1);
  transition: .4s;
  border-radius: 34px;
}

.switch-label:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

.switch-input:checked + .switch-label {
  background-color: var(--primary-color);
}

.switch-input:checked + .switch-label:before {
  transform: translateX(26px);
}

/* Color Item improvements */
.color-item {
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
  border: 1px solid var(--light-gray-1);
}

.color-swatch {
  height: 120px;
  margin: 0;
  border-radius: 0;
}

.color-info {
  padding: 1rem;
}

.color-info h4 {
  margin: 0;
  font-size: var(--font-size-base);
}

.color-info code {
  display: block;
  margin: 0.5rem 0;
  font-size: var(--font-size-sm);
  color: var(--text-gray);
}

.color-info p {
  margin: 0.25rem 0;
  font-size: var(--font-size-sm);
  font-weight: var(--text-weight-medium);
}

.color-info small {
  display: block;
  margin-top: 0.25rem;
  color: var(--text-gray);
}

/* Form improvements */
.form-check {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.form-check-input {
  margin-right: 0.5rem;
}

/* Map container improvements */
.static-map-container {
  height: 400px;
  border-radius: var(--border-radius);
  overflow: hidden;
  border: 1px solid var(--light-gray-1);
}

/* Map Component Styles */
.style-examples .static-map-container {
  height: 300px;
  margin-bottom: 2rem;
  border: 1px solid var(--light-gray-1);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.style-examples .mapboxgl-map {
  width: 100%;
  height: 100%;
}

.style-examples .mapboxgl-control-container {
  display: none; /* Hide controls in static map */
}

/* Add description text for maps */
.map-description {
  margin-top: 0.5rem;
  font-size: var(--font-size-sm);
  color: var(--text-gray);
}

/* Interactive map specific styles */
.interactive-map .mapboxgl-control-container {
  display: block; /* Show controls in interactive map */
}

/* Map Component Styles */
.style-examples .map-container {
  height: 400px;
  border-radius: var(--border-radius);
  overflow: hidden;
  border: 1px solid var(--light-gray-1);
  margin-bottom: 1rem;
}

.style-examples .static-map-container {
  height: 300px;
}

.map-description {
  font-size: var(--font-size-sm);
  color: var(--text-gray);
  margin-top: 0.5rem;
}

/* Map controls */
.mapboxgl-control-container {
  font-family: var(--font-family-base);
}

.mapboxgl-ctrl-top-right {
  top: 1rem;
  right: 1rem;
}

/* Hide attribution on style guide examples */
.style-examples .mapboxgl-ctrl-attrib {
  display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .style-examples .map-container,
  .style-examples .static-map-container {
    height: 250px;
  }
}

/* Override any Bootstrap hover effects */
.btn:hover {
  transform: none !important;
}

/* Add specific styles for the search input */
.search-input {
  border: none !important;  /* Force remove border */
  box-shadow: none !important;  /* Remove any shadow */
  background: transparent !important;  /* Make background transparent */
  padding: 8px !important;  /* Adjust padding */
}

.search-input:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

/* The search input wrapper already provides the visual container */
.search-input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2px 8px 2px 16px;
  border: 1px solid #DDDDDD;
  border-radius: 40px;
  background: white;
  box-shadow: var(--shadow-light);
  transition: box-shadow 0.2s ease;
  position: relative;
  margin: 0; /* Remove any margin */
  gap: 0; /* Remove any gap */
}

.search-input {
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;
  padding: 8px !important;
  margin: 0 !important; /* Remove any margin */
  width: 100% !important;
  height: auto !important; /* Let the wrapper control height */
}

.search-input:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

/* Global Modal Styles */
.modal {
  --modal-padding: 16px;
  --modal-header-height: 56px;
}

.modal-dialog {
  margin: 1.75rem auto; /* Center modal on desktop */
  width: calc(100% - (var(--modal-padding) * 2));
  max-width: 500px;
}

.modal-content {
  border: none;
  border-radius: 8px;
  box-shadow: var(--shadow-large);
}

.modal-header {
  padding: 16px 20px;
  border-bottom: 1px solid var(--light-gray-1);
}

.modal-body {
  padding: 16px 20px;
}

/* Mobile Modal Styles */
@media (max-width: 768px) {
  .modal {
    padding: 0 !important;
  }

  .modal-dialog {
    margin: 0; /* Remove margin on mobile */
    width: 100%;
    max-width: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .modal.show .modal-dialog {
    transform: translateY(0);
  }

  .modal-content {
    border-radius: 12px 12px 0 0;
    max-height: 85vh;
    display: flex;
    flex-direction: column;
  }

  .modal-header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1020;
    padding: 16px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .modal-body {
    padding: 16px;
    overflow-y: auto;
    flex: 1 1 auto;
  }

  .modal-footer {
    position: sticky;
    bottom: 0;
    background: white;
    padding: 16px;
    border-top: 1px solid var(--light-gray-1);
  }
}

/* Alert base styles */
.alert {
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 12px;
  border: none;
  font-size: 15px;
}

.alert-success {
  background-color: #e8f5e9;
  color: #1b5e20;
}

.alert-warning {
  background-color: #fff3e0;
  color: var(--text-color);
  display: flex;
  align-items: center;
  gap: 8px;
}

.inline-link {
  color: var(--link-color);
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
  box-shadow: none;
}

.inline-link:hover,
.inline-link:focus {
  text-decoration: underline;
  color: var(--link-hover-color);
  background: none;
  box-shadow: none;
}

.error-boundary {
  padding: 20px;
  text-align: center;
  margin: 20px auto;
  max-width: 600px;
}
