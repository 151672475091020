.skeleton-card {
  background: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: auto;
}

.skeleton-image {
  width: 70px;
  height: 70px;
  min-width: 70px;
  border-radius: 50%;
  background: #e0e0e0;
}

.skeleton-title {
  height: 24px;
  width: 70%;
  background: #e0e0e0;
  margin-left: 12px;
  border-radius: 4px;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.skeleton-address {
  height: 16px;
  width: 60%;
  background: #e0e0e0;
  margin-bottom: 16px;
  border-radius: 4px;
}

.skeleton-description {
  height: 40px;
  background: #e0e0e0;
  margin-bottom: 16px;
  border-radius: 4px;
}

.skeleton-categories {
  display: flex;
  gap: 8px;
}

.skeleton-tag {
  height: 24px;
  width: 100px;
  background: #e0e0e0;
  border-radius: 12px;
}

/* Animation */
.pulse {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: .6;
  }
  50% {
    opacity: .3;
  }
  100% {
    opacity: .6;
  }
}

/* Update skeleton description height */
.skeleton-description {
  height: 40px;
  background: #e0e0e0;
  margin-bottom: 16px;
  border-radius: 4px;
}

/* Update mobile responsiveness */
@media (max-width: 768px) {
  .skeleton-card {
    width: 280px !important;
    min-width: 300px !important;
    min-height:225px !important;
    padding: 12px;
    margin: 0;
    flex-shrink: 0;
  }
  
  .card-header {
    gap: 8px;
    margin-bottom: 4px;
    padding-bottom: 4px;
    min-height: 48px !important;
  }
  
  .skeleton-image {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
  }
  
  .skeleton-title {
    height: 20px;
    margin-bottom: 4px;
  }
  
  .skeleton-description {
    height: 16px;
    margin-bottom: 8px;
  }
  
  .skeleton-tag {
    height: 20px;
    width: 80px;
  }
}
