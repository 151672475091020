/* Footer Container */
.footer {
    background-color: var(--background-color);
    padding: 48px 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-container {
    max-width: 1050px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    gap: 48px;
    align-items: start;
}

.footer-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.footer-column-right {
    justify-self: start;
}

.footer-logo {
    margin-bottom: 8px;
    align-self: flex-start;
}

.footer-logo svg {
    width: 105px;
    height: 25px;
}

.footer-description {
    color: var(--text-gray);
    font-size: 14px;
    margin: 0;
    max-width: 280px;
}

.footer-copyright {
    color: var(--text-gray);
    font-size: 14px;
    margin: 0;
}

.footer-link {
    color: var(--text-gray);
    text-decoration: none;
    font-size: 14px;
    transition: color 0.2s ease;
    align-self: flex-start;
}

.footer-link:hover {
    color: var(--primary-color);
    text-decoration: none;
}

/* Responsive Design */
@media (max-width: 768px) {
    .footer-container {
        grid-template-columns: 1fr 1fr;
        gap: 32px;
    }

    .footer-column:first-child {
        grid-column: 1 / -1;
    }

    .footer-column-right {
        justify-self: start;
    }
}

@media (max-width: 576px) {
    .footer-container {
        grid-template-columns: 1fr;
        gap: 24px;
    }

    .footer {
        padding: 32px 16px;
    }
}

/* Add this to ensure proper layout */
#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    flex: 1 0 auto;
}

.footer-language-selector {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 16px 0;
}

.footer-language-select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
}

.globe-icon {
    color: #666;
    font-size: 16px;
}
