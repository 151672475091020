.skeleton-pulse {
    animation: pulse 1.5s infinite;
    background: #e0e0e0;
  }
  
  @keyframes pulse {
    0% {
      opacity: .6;
    }
    50% {
      opacity: .3;
    }
    100% {
      opacity: .6;
    }
  }
  
  /* Container Layout */
  .profile-container {
    max-width: 1100px;
    margin: 0 auto;
    padding: calc(var(--spacing-unit) * 2);
    padding-top: 2rem;
  }
  
  /* Profile Header */
  .profile-header {
    display: flex;
    align-items: center;
    gap: calc(var(--spacing-unit) * 2);
    margin-bottom: 2rem;
    margin-top: 40px;
  }
  
  .profile-image-container {
    width: 120px !important;
    height: 120px !important;
    min-width: 120px !important;
    min-height: 120px !important;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .skeleton-image {
    width: 100%;
    height: 100%;
    background: #e0e0e0;
  }
  
  /* Title Area */
  .profile-title-container {
    flex: 1;
    min-width: 0;
  }
  
  .skeleton-title {
    height: 36px;
    width: 60%;
    border-radius: 4px;
    margin-bottom: 8px;
  }
  
  .skeleton-company-info {
    height: 20px;
    width: 40%;
    border-radius: 4px;
  }
  
  /* Content Layout */
  .profile-content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: calc(var(--spacing-unit) * 4);
    align-items: start;
  }
  
  /* Main Content */
  .profile-main {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-unit) * 2);
  }
  
  .skeleton-section-title {
    height: 28px;
    width: 30%;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  
  .skeleton-description {
    height: 100px;
    border-radius: 4px;
    margin-bottom: 24px;
  }
  
  /* Category Tags */
  .category-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 24px;
  }
  
  .skeleton-tag {
    height: 32px;
    width: 100px;
    border-radius: 16px;
  }
  
  /* Offers Section */
  .skeleton-accordion {
    height: 65px;
    border-radius: 8px;
    margin-bottom: 16px;
  }
  
  /* Sidebar */
  .profile-sidebar {
    position: sticky;
    top: 2rem;
    background: #fff;
    border-radius: var(--border-radius-medium);
    padding: calc(var(--spacing-unit) * 2);
    height: fit-content;
  }
  
  .skeleton-map {
    height: 400px;
    border-radius: 8px;
    margin-bottom: 16px;
  }
  
  .skeleton-contact-btn {
    height: 48px;
    border-radius: 8px;
    margin-bottom: 12px;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .profile-content {
      grid-template-columns: 1fr;
    }
  
    .profile-sidebar {
      position: static;
      margin-top: calc(var(--spacing-unit) * 2);
    }
  
    .profile-image-container {
      width: 100px !important;
      height: 100px !important;
      min-width: 100px !important;
      min-height: 100px !important;
    }
  
    .skeleton-map {
      height: 300px;
    }
  
    .skeleton-title {
      height: 24px;
      width: 70%;
    }
  
    .skeleton-company-info {
      height: 16px;
    }
  
    .skeleton-description {
      height: 60px;
    }
  
    .skeleton-contact-btn {
      height: 40px;
    }
  }