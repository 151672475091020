.project-info {
  display: flex;
  gap: 2rem;
  margin: 2rem 0;
}

.project-info__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-info__image {
  flex: 1;
  max-width: 50%;
}

.project-info__image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

.project-info__link {
  color: #0066cc;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .project-info {
    flex-direction: column;
  }

  .project-info__image {
    max-width: 100%;
  }
}

.project-banner {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: var(--border-radius-medium);
}
