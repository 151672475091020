.skeleton-pulse {
    animation: pulse 1.5s infinite;
    background: #e0e0e0;
  }
  
  @keyframes pulse {
    0% { opacity: .6; }
    50% { opacity: .3; }
    100% { opacity: .6; }
  }
  
  .skeleton-header {
    display: flex;
    margin-bottom: 40px;
    box-shadow: var(--shadow-large);
    border-radius: var(--border-radius-medium);
    background-color: white;
    height: 550px;
  }
  
  .skeleton-title {
    height: 40px;
    width: 80%;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .skeleton-description {
    height: 20px;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 12px;
  }
  
  .skeleton-map {
    width: 100%;
    height: 100%;
    border-radius: 0 var(--border-radius-medium) var(--border-radius-medium) 0;
  }
  
  /* Company List Skeleton */
  .skeleton-company-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px 0;
  }
  
  .skeleton-company-card {
    height: 150px;
    border-radius: var(--border-radius-medium);
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
 
    .skeleton-header {
      flex-direction: column;
      height: auto;
      margin-bottom: 20px;
      border-radius: var(--border-radius-medium);
    }
  
    .project-header-info {
      padding: 25px;
      min-height: 600px !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  
    .skeleton-title {
      height: 32px;
      width: 90%;
      margin-bottom: 25px;
    }
  
    .skeleton-description {
      height: 16px;
      margin-bottom: 15px;
    }
  
    .skeleton-description:last-child {
      width: 85%;
      margin-bottom: 25px;
    }
  
    .skeleton-map {
      height: 300px;
      border-radius: 0 0 var(--border-radius-medium) var(--border-radius-medium);
    }
  
    .skeleton-company-list {
      display: flex;
      overflow-x: auto;
      padding: 15px;
      gap: 15px;
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;
    }
  
    .skeleton-company-card {
      flex: 0 0 280px;
      width: 280px;
      min-width: 280px;
      height: 150px;
      scroll-snap-align: start;
      margin-right: 0;
    }
  
    /* Hide scrollbar but keep functionality */
    .skeleton-company-list::-webkit-scrollbar {
      display: none;
    }
  }