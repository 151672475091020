.company-list-container {
  margin-top: 40px;
  max-width: 1050px !important;
}

.company-list-container h2 {
  font-size: var(--font-size-2xl);
  font-weight: var(--text-weight-semibold);
  margin-bottom: var(--space-4);
}

.company-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .company-grid {
    display: flex;
    overflow-x: auto;
    gap: 8px !important;
    padding: 8px !important;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    padding-left: 20px !important;
    margin-right: -20px !important;
  }

  .company-grid .company-card {
    flex: 0 0 300px !important;
    width: 300px !important;
    min-width: 300px !important;
    height: 150px !important;
    scroll-snap-align: start;
    margin: 0 !important;
    padding: calc(var(--spacing-unit));
  }

  .company-grid .card-title {
    font-size: var(--text-lg) !important;
    line-height: var(--line-height-tight) !important;
    height: 2.4em !important;
    min-height: 2.4em !important;
    margin-bottom: 4px !important;
    display: flex !important;
    align-items: center !important;
  }

  .company-grid .card-title > span {
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 2 !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    width: 100%;
  }
}

.card-title {
  margin: 0;
  font-family: var(--font-family-base);
  font-size: var(--text-xl) !important;
  font-weight: var(--text-weight-semibold);
  height: 2.4em !important;
  min-height: 2.4em !important;
  max-height: 2.4em !important;
  width: 100%;
  display: flex !important;
  align-items: center !important;
}

.card-title > span {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  line-height: var(--line-height-tight);
  width: 100%;
}

.company-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 100% !important;
  min-height: 220px !important;
}

.company-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
}

.company-card h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.company-card p {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 10px;
}

.company-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.company-tag {
  background-color: #e0e0e0;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8em;
}

.show-more-btn {
  display: block;
  margin: 20px auto 0;
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.show-more-btn:hover {
  background-color: #0a1b2a;
}

.company-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.export-btn {
  padding: 4px 8px;
  margin: 0;
  border: none;
  background: none !important;
  box-shadow: none !important;
  text-decoration: none;
  color: var(--link-color) !important;
  font-weight: normal;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 0.9rem;
  white-space: nowrap;
  transition: opacity 0.3s ease;
}

.export-btn:disabled {
  color: var(--text-gray) !important;
  opacity: 0.7;
}

.export-btn:hover,
.export-btn:focus,
.export-btn:active {
  text-decoration: none;
  background: none !important;
  box-shadow: none !important;
  color: var(--link-hover-color) !important;
}

.export-btn:focus {
  outline: none;
}

.export-btn::after,
.export-btn::before {
  display: none;
}
