.sitemap-page {
  max-width: 1050px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.sitemap-section {
  margin: 2rem 0;
}

.sitemap-section h2 {
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.sitemap-section ul {
  list-style: none;
  padding: 0;
}

.sitemap-section li {
  margin: 0.5rem 0;
}

.sitemap-section a {
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.2s;
}

.sitemap-section a:hover {
  color: var(--primary-color);
}

.companies-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.sitemap-loading {
  text-align: center;
  padding: 2rem;
} 