/* Header Container */
.header {
    background: white;
    border-bottom: 1px solid #DDDDDD;
    padding: 0;
    width: 100%;
    height: 80px;
}

/* Navbar */
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 100%;
    width: 100%;
    background: white;
}

/* Add this new class */
.header-left-center-group {
    display: flex;
    align-items: left;
    gap: 10px; /* Adjust this value to control spacing between left and center */
}

.header-left-group {
  display: flex;
  flex-direction: row; /* Ensure horizontal alignment */
  align-items: center;
  gap: 12px;
  flex: 0 0 auto; /* Prevent unwanted stretching */
}

/* Remove any gap properties from these classes */
.header-left {
    flex: 0 0 auto;
    display: flex;
    align-items: center; /* Changed from 'left' to 'center' */
    gap: 12px; /* Add consistent gap */
    max-width: fit-content;
}

.header-center {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    padding: 0;
    margin: 0 24px; /* Add margin for spacing */
}

.header-right {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto; /* This will push it to the right */
}

/* Navbar Brand */
.navbar-brand {
    display: flex;
    align-items: center;
    margin: 0; /* Remove auto margin */
}
@media (max-width: 768px) {
  .header-center {
      display: none;
  }
}




/* Navbar Buttons */
.btn {
    padding: calc(var(--spacing-unit) * 1.2) calc(var(--spacing-unit) * 2);
    font-size: 1rem;
    border-radius: var(--border-radius);
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Primary Button */
.btn-primary {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
}

.btn-primary:hover {
    background-color: var(--accent-color);
}

/* Secondary Button */
.btn-secondary {
    background-color: var(--light-gray);
    color: var(--text-color);
    border: none;
}

.btn-secondary:hover {
    background-color: #ccc;
}

/* Danger Button */
.btn-danger {
    background-color: #cd7167;
    color: #fff;
    border:none;
}

.btn-danger:hover {
    background-color: #c4aaa7;
}

/* Sticky Header */
.sticky-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    background: white;
    border-bottom: 1px solid #DDDDDD;
    box-shadow: 0 2px 8px rgba(0,0,0,0.08);
    transition: transform 0.3s ease;
}

/* Add padding to the body to prevent content jump */
body {
    padding-top: 80px; /* Match the header height */
}

/* Optional: Add hide/show on scroll behavior */
.header-hidden {
    transform: translateY(-100%);
}

/* Ensure mobile search modal stays on top */
.mobile-search-modal {
    position: fixed;
    top: 80px; /* Update to be below header */
    z-index: 1049; /* Just below header */
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 600px;
    background: white;
    padding: 12px 16px;
    border-radius: 0 0 12px 12px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.15);
    animation: slideDown 0.3s ease-out;
}

/* Add these new styles */
.mobile-search-header {
  display: flex;
  align-items: center;
  gap: 12px; /* Space between close button and search form */
}

.mobile-search-close {
  border: none;
  background: transparent;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

.mobile-search-close:hover {
  color: #333;
}

@keyframes slideDown {
    from {
        transform: translate(-50%, -100%);
    }
    to {
        transform: translate(-50%, 0);
    }
}

/* Mobile Search Trigger - Updated */
.mobile-search-trigger {
    display: none;
    border: none;
    border-radius: 50% !important;
    width: 40px !important; /* Match auth button size */
    height: 40px !important; /* Match auth button size */
    min-width: 40px !important; /* Ensure consistent width */
    min-height: 40px !important; /* Ensure consistent height */
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 0px !important;
    background: var(--primary-color) !important;
    padding: 0 !important;
    color: white !important;
}

.mobile-search-trigger .search-icon {
    color: white !important;
    font-size: 16px !important; /* Slightly larger icon */
}

@media (max-width: 768px) {
    .header-center {
        display: none; /* Hide the search bar on mobile */
    }

    .mobile-search-trigger {
        display: flex; /* Only show on mobile */
    }

    .header {
    }

    /* Adjust logo size for mobile */
    .logo {
        height: 24px;
    }

    /* Make avatar smaller on mobile */
    .avatar {
        width: 40px !important;
        height: 40px !important;
    }

    /* Adjust header layout */
    .navbar {
        padding: 0 0px;
    }

    /* Position search trigger next to logo */
    .header-left {
        display: flex;
        align-items: left;
    }

    .mobile-search-trigger {
        margin-left: 0px !important;
    }
}

@media (min-width: 769px) {
    .mobile-search-trigger {
        display: none !important; /* Hide search trigger on desktop */
    }
}

/* Style for the logo */
.logo {
    height: 32px;
    width: auto;
    /* Remove any color filters if present */
    filter: none;
}

/* Search Bar Styling */
.search-form {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.search-input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 2px 8px 2px 16px;
    border: 1px solid #DDDDDD;
    border-radius: 40px;
    background: white;
    box-shadow: var(--shadow-light);
}

.search-input {
    border: none !important;
    box-shadow: none !important;
    background: transparent !important;
    padding: 8px !important;
    margin: 0 !important;
    width: 100% !important;
    height: auto !important;
}

.search-button {
    min-width: 32px !important;
    min-height: 32px !important;
    width: 32px !important;
    height: 32px !important;
    border: none !important;
    border-radius: 50% !important;
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background: var(--primary-color) !important;
    margin-left: 8px !important;
    flex-shrink: 0 !important;
}

.search-icon {
    color: white !important;
    font-size: 14px !important;
}

/* Header right section */
.header-right {
    display: flex;
    align-items: center;
    gap: 8px;
}

.host-button {
    padding: 12px 16px;
    border-radius: 22px;
    font-size: 14px;
    font-weight: 600;
    color: #222222;
    background: transparent;
    border: none;
    transition: background-color 0.2s ease;
}

.host-button:hover {
    background-color: #F7F7F7;
}

.globe-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    transition: background-color 0.2s ease;
}

.globe-button:hover {
    background-color: #F7F7F7;
}

/* User menu button */
.user-menu {
    position: relative;
    cursor: pointer;
}

/* Update avatar styling */
.avatar {
  width: 50px !important;
  height: 50px !important;
  min-width: 50px !important;
  min-height: 50px !important;
  border-radius: 50% !important;
  background: white !important;
  border: 1px solid var(--light-gray-1) !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  overflow: hidden !important;
  position: relative !important;
  cursor: pointer;
  transition: box-shadow 0.2s ease;
}

.avatar-img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  background: transparent !important;
  position: relative !important;
  z-index: 1 !important;
}

/* Add white background pseudo-element */
.avatar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 0;
}

/* Update placeholder styling */
.avatar-placeholder {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: transparent !important;
  color: var(--primary-color) !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  position: relative !important;
  z-index: 1 !important;
}

/* Maintain mobile responsiveness */
@media (max-width: 768px) {
  .avatar {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
  }

  .avatar-placeholder {
    font-size: 20px !important;
  }
}

.avatar:hover {
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.dropdown-menu {
    position: fixed;
    top: 80px;
    right: 0;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 16px rgba(0,0,0,0.12);
    min-width: 200px;
    z-index: 1000;
    margin: -10px;
    padding: 10px;
    overflow: hidden;
}

/* Add padding to create a hoverable area between avatar and menu */
.user-menu::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 8px;
}

.user-menu:hover .dropdown-menu,
.dropdown-menu:hover {
    display: block;
}

.menu-item,
.menu-item:link,
.menu-item:visited,
.menu-item:hover,
.menu-item:active {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    cursor: pointer;
    text-decoration: none !important;
    font-family: var(--font-family-base);
    font-weight: normal;
    border-radius: 8px;
    color: #222222;
    font-size: 14px;
    
}

.menu-item:hover {
    background-color: #F7F7F7;
    border-radius: var(--border-radius-small) !important;

}

/* Reset button styles */
button.menu-item {
    width: 100%;
    margin: 0;
    appearance: none;
    -webkit-appearance: none;
}

.menu-divider {
    height: 1px;
    background-color: #DDDDDD;
    margin: 8px 0;
}

/* Mobile Search Trigger - Updated */
.mobile-search-trigger {
    display: none;
    border: none;
    border-radius: 50% !important;
    width: 32px; /* Match search button size */
    height: 32px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 0px;
    background: var(--primary-color) !important;
    min-height: 32px !important;
    min-width: 32px !important;
    padding: 0 !important;
}

.mobile-search-trigger .search-icon {
    color: white !important;
    font-size: 14px !important; /* Match search button icon size */
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .mobile-search-trigger {
        display: flex;
    }
}

/* Add padding to create a hoverable area between avatar and menu */
.user-menu::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 8px;
}

.user-menu:hover .dropdown-menu,
.dropdown-menu:hover {
    display: block;
}

.menu-item,
.menu-item:link,
.menu-item:visited,
.menu-item:hover,
.menu-item:active {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    cursor: pointer;
    text-decoration: none !important;
    font-family: var(--font-family-base);
    font-weight: normal;
    border-radius: 8px;
    color: #222222;
    font-size: 14px;
}

.menu-item:hover {
    background-color: #F7F7F7;
}

/* Reset button styles */
button.menu-item {
    width: 100%;
    margin: 0;
    appearance: none;
    -webkit-appearance: none;
}

.menu-divider {
    height: 1px;
    background-color: #DDDDDD;
    margin: 8px 0;
}

/* Update the logo styles */
.navbar-brand img {
    height: 25px;
    width: auto;
    object-fit: contain;
}

@media (max-width: 768px) {
    .navbar-brand img {
        height: 25px;
    }
}

.ai-indicator {
  padding: 2px 8px;
  font-size: 11px;
  font-weight: 600;
  border-radius: 12px;
  background: linear-gradient(135deg, #00a3ff, #0066ff);
  color: white;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  opacity: 0.9;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 163, 255, 0.2);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.5px;
  position: relative;
  margin-right: 12px;
}

/* Add divider after AI indicator */
.ai-indicator::after {
  content: '';
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 1px;
  background: rgba(221, 221, 221, 1);  /* Removed transparency */
  transition: background 0.3s ease;
  width: 1.5px;  /* Increased from 1px */

}

/* Optional: Change divider color on hover */
.search-input-wrapper:hover .ai-indicator::after {
  background: rgba(221, 221, 221, 1);
}

/* Adjust for mobile */
@media (max-width: 768px) {
  .ai-indicator::after {
    height: 14px;
  }
}

.search-input-wrapper {
  /* ... existing styles ... */
  transition: all 0.3s ease;
  border: 1px solid rgba(221, 221, 221, 0.8);
}

.search-input-wrapper:focus-within {
  border-color: #00a3ff;
  box-shadow: 0 4px 15px rgba(0, 163, 255, 0.15);
}

/* Add a subtle animation for the search icon */

/* Update mobile search modal styles */
.mobile-search-modal {
  /* ... existing styles ... */
  padding: 12px 16px;
}

/* Adjust mobile search input wrapper */
@media (max-width: 768px) {
  .search-input-wrapper {
    margin: 0;
    width: 100%;
  }

  /* Slightly smaller AI indicator for mobile */
  .ai-indicator {
    height: 20px;
    padding: 2px 6px;
    font-size: 10px;
  }

  .ai-icon {
    font-size: 9px;
  }

  /* Ensure proper spacing in mobile search */
  .mobile-search-header .search-form {
    flex: 1;
  }

  /* Adjust animation for smoother mobile experience */
  .mobile-search-modal {
    animation: slideDown 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

/* Base button styles */
button, 
.btn,
.btn-primary,
.btn-secondary,
.login-link,
.signup-link {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 500;
  letter-spacing: -0.1px;
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColor, #4a4a4a);
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  -webkit-text-size-adjust: 100%;
  transition: var(--transitionStyleButton, ease-in-out 0.1s);
}

/* Header auth buttons */
.header-auth-buttons {
  display: flex;
  align-items: center;
  height: 100%;
}

/* Login and signup links */
.login-link,
.signup-link {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 12px;
  text-decoration: none;
  border-bottom: 0 solid transparent;
  font-family: "Poppins", Helvetica, Arial, sans-serif !important;
  font-weight: 500 !important;
  letter-spacing: -0.1px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--matterColor, #4a4a4a) !important;
}

.login-link:hover,
.signup-link:hover {
  color: var(--marketplaceColor, #155579) !important;
  border-bottom: 3px solid var(--marketplaceColor, #155579);
  text-decoration: none;
}

/* Mobile styles */
@media (max-width: 768px) {
  .login-link,
  .signup-link {
    padding: 0 8px;
    font-size: 13px !important;
    line-height: 18px !important;
  }
}

/* Logo styles */
.logo {
  height: 32px;
  width: auto;
}

/* Mobile logo styles */
.logo-mobile {
  display: none;
  height: 24px;
  width: auto;
}

@media (max-width: 768px) {
  .logo {
    display: none;
  }
  
  .logo-mobile {
    display: block;
  }
}

/* Desktop auth buttons */
.desktop-auth {
  display: flex;
  gap: 8px;
}

/* Mobile auth trigger */
.mobile-auth-trigger {
  display: none;
  border: none;
  background: white !important;
  padding: 0;
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  min-height: 40px !important;
  color: var(--primary-color) !important;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.auth-icon {
  font-size: 40px !important;
  display: flex;
}

/* Desktop auth buttons */
.desktop-auth {
  display: flex;
  gap: 8px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .desktop-auth {
    display: none;
  }

  .mobile-auth-trigger {
    display: flex;
  }
}

/* Desktop styles */
@media (min-width: 769px) {
  .mobile-auth-trigger {
    display: none !important;
  }
}

/* Logo styles */
.navbar-brand {
  display: flex;
  align-items: center;
  margin: 0; /* Remove auto margin */
}

.logo {
  height: 32px;
  width: auto;
}

/* Mobile logo styles */
.logo-mobile {
  display: none;
  height: 24px;
  width: auto;
}

/* Desktop styles */
@media (min-width: 769px) {
  .navbar-brand {
    margin-right: auto; /* Push everything else to the right */
  }
  
  .logo {
    display: block;
  }
  
  .logo-mobile {
    display: none;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .logo {
    display: none;
  }
  
  .logo-mobile {
    display: block;
  }

  .navbar-brand {
    order: 2;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto; /* Center only on mobile */
  }

  .header-left-group {
    order: 1;
  }

  .header-right {
    order: 3;
  }
}

/* Desktop styles */
@media (min-width: 769px) {
  .mobile-search-trigger {
    display: none;
  }

  .header-center {
    display: flex;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .header-center {
    display: none;
  }

  .mobile-search-trigger {
    display: flex;
  }

  .navbar {
    padding: 0 16px;
  }

  .header-left-group {
    order: 1;
  }

  .navbar-brand {
    order: 2;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
  }

  .header-right {
    order: 3;
  }
}

/* Update search trigger to match new size */
.mobile-search-trigger {
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  min-height: 40px !important;
}

.mobile-search-trigger .search-icon {
  font-size: 16px !important; /* Keep search icon proportional */
}

.user-email {
  padding: 12px 16px;
  color: #7e7e7e;
  font-size: 13px;
  word-break: break-all;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default; /* Removes the pointer cursor */
}

/* Update dropdown menu width to accommodate longer emails */
.dropdown-menu {
  min-width: 240px;
  max-width: 320px;
}

/* Common styles for user info items */
.user-info {
  padding: 12px 16px;
  font-size: 13px;
  word-break: break-all;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default; /* Removes the pointer cursor */
}

.company-name {
  color: #4a4a4a;
  font-weight: 500;
}

.user-email {
  color: #7e7e7e;
}

/* Update dropdown menu width to accommodate longer text */
.dropdown-menu {
  min-width: 240px;
  max-width: 320px;
}

/* Profile info section */
.profile-info-section {
  padding: 12px 16px;
  background: #f5f5f5;
  border-radius: var(--border-radius-small);
}

.section-heading {
  font-family: var(--font-family-base);
  font-size: var(--font-size-xs);
  text-transform: uppercase;
  color: #666;
  margin-bottom: 8px;
  letter-spacing: 0.5px;
  font-weight: var(--text-weight-medium);
}

/* Common styles for user info items */
.user-info {
  padding: 4px 0;
  font-family: var(--font-family-base);
  font-size: var(--font-size-sm);
  word-break: break-all;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
}

.company-name {
  color: #4a4a4a;
  font-weight: var(--text-weight-medium);
}

.user-email {
  color: #7e7e7e;
  font-weight: var(--text-weight-normal);
}

/* Update dropdown menu width to accommodate longer text */
.dropdown-menu {
  min-width: 240px;
  max-width: 320px;
}

/* Menu items styling */
.menu-item {
  display: block;
  width: 100%;
  padding: 12px 16px;
  text-decoration: none;
  color: #4a4a4a;
  font-size: 14px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.menu-item:hover {
  background-color: #f5f5f5;
  color: #333;
  text-decoration: none; /* Prevent link underline on hover */
}

/* Remove any existing button styles and link styles */
.dropdown-menu button.menu-item,
.dropdown-menu a.menu-item {
  font-family: inherit;
  font-weight: inherit;
  width: 100%;
  border-radius: 0;
  outline: none; /* Remove focus outline */
}

/* Remove link default behaviors */
.dropdown-menu a.menu-item:hover,
.dropdown-menu a.menu-item:focus,
.dropdown-menu a.menu-item:active {
  text-decoration: none;
  color: #333;
}

/* Ensure consistent focus styles if needed */
.menu-item:focus {
  background-color: #f5f5f5;
  outline: none;
}

/* Menu divider */
.menu-divider {
  height: 1px;
  background-color: #eee;
  margin: 4px 0;
}

