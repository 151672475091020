.skeleton-pulse {
    animation: pulse 1.5s infinite;
    background: #e0e0e0;
    border-radius: 4px;
  }
  
  @keyframes pulse {
    0% { opacity: .6; }
    50% { opacity: .3; }
    100% { opacity: .6; }
  }
  
  /* Profile Completion Section */
  .skeleton .profile-completion-header {
    background: white;
  }
  
  .skeleton .profile-completion-progress {
    height: 4px;
    border-radius: 2px;
  }
  
  .skeleton .completion-task {
    background: white;
    height: 80px;
  }
  
  /* Project Cards */
  .skeleton.project-card {
    background: white;
    pointer-events: none;
  }
  
  .skeleton .project-card-logo {
    background: #e0e0e0;
    height: 160px;
  }
  
  .skeleton .project-card-content {
    padding: 16px;
  }
  
  .skeleton-text {
    border-radius: 4px;
    margin-bottom: 8px;
  }
  
  /* Step Cards */
  .skeleton.step-card {
    background: white;
    padding: 20px;
    height: 120px;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .skeleton .project-card-logo {
      height: 200px;
    }
  
    .skeleton .completion-task {
      flex: 0 0 calc(50% - 6px) !important;
      min-width: calc(50% - 6px) !important;
      height: 70px;
    }
  
    .skeleton.step-card {
      height: 100px;
    }
  }